import React, { useState, useEffect, useRef } from "react";

import "@stylesComponents/Table.scss";

import PrevIcon from "@icons/table_left_icon.svg";
import NextIcon from "@icons/table_right_icon.svg";

export default function Table({ columns, data = [], withActions, pagination }) {
  const [newData, setNewData] = useState(data);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(1);
  const [pageState, setPageState] = useState({
    currentPage: 1,
    showItems: 10,
    totalItems: newData.length,
    pageNeighbours: 1,
    totalPages() {
      return Math.ceil(this.totalItems / this.showItems);
    },
    getPages() {
      const currentLess2 = this.currentPage - 2;
      const currentLess1 = this.currentPage - 1;
      const currentPlus1 = this.currentPage + 1;
      const currentPlus2 = this.currentPage + 2;
      const prev = this.currentPage <= 1 ? null : currentLess1;
      const prevNeighbour2 = currentLess2 < 1 ? null : currentLess2;
      const prevNeighbour1 = currentLess1 < 1 ? null : currentLess1;
      const next = this.currentPage >= this.totalPages() ? null : currentPlus1;
      const nextNeighbour2 = currentPlus2 > this.totalPages() ? null : currentPlus2;
      const nextNeighbour1 = currentPlus1 > this.totalPages() ? null : currentPlus1;
      return {
        prev,
        prevNeighbour2,
        prevNeighbour1,
        current: this.currentPage,
        nextNeighbour1,
        nextNeighbour2,
        next
      };
    },
    range() {
      return {
        from: (this.showItems * this.currentPage) - (this.showItems),
        to: (this.showItems * this.currentPage)
      };
    }
  });
  const actionsRef = useRef(null);
  const tableRef = useRef(null);

  const handleSortable = (name) => {
    const dataSorted = newData.sort((a, b) => {
      if (a[name] < b[name]) return order * (-1);
      if (a[name] > b[name]) return order;
      return 0;
    });
    setOrder(order * (-1));
    setNewData(dataSorted);
  };

  const handleScroll = () => {
    if (withActions && actionsRef.current) {
      actionsRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleGoToPage = (page) => {
    handleScroll();
    setPageState({
      ...pageState,
      currentPage: page
    });
  };

  const handleChangeShowItems = (event, current) => {
    const amount = parseInt(event.target.value, 10);
    const newTotalPages = Math.ceil(pageState.totalItems / amount);
    handleScroll();
    setPageState((current * pageState.totalItems) > pageState.totalItems ? {
      ...pageState,
      currentPage: current > newTotalPages ? newTotalPages : current,
      showItems: amount
    } : {
      ...pageState,
      showItems: amount
    });
  };

  const dataWithPagination = () => newData.slice(pageState.range().from, pageState.range().to);

  const renderData = (row) => {
    const { id, ...newRow } = row;
    const cells = columns.map(({ name, cell, width, sticky }) => (
      { name, text: newRow[name], cell, width, sticky }
    ));
    return (
      <div key={id} className="table__row">
        {
          cells.map(({ name, text = "-", cell: Cell, width, sticky }, indexCell) => {
            const key = `${name}${indexCell}`;
            if (!Cell) {
              return (
                <p
                  key={key}
                  className={`table__cell${sticky ? " table__sticky-column" : ""}`}
                  style={{ width, minWidth: width }}
                >
                  {text}
                </p>
              );
            }
            return <Cell key={key} width={width} row={row} columnId={name} />;
          })
        }
      </div>
    );
  };

  const renderOptionPagination = (amount) => (
    `${((amount * pageState.currentPage) - amount) + 1}-${
      ((pageState.showItems === amount) && (dataWithPagination().length < amount))
        ? (dataWithPagination().length + ((amount * pageState.currentPage) - amount))
        : (amount * pageState.currentPage)
    }`
  );

  let Actions;
  if (withActions) Actions = withActions(data);

  useEffect(() => {
    setNewData(data);
    setPageState({
      ...pageState,
      totalItems: data.length
    });
    setLoading(false);
  }, [data]);

  return (
    <>
      {
        withActions && (
          <div ref={actionsRef} className="table__actions">
            <Actions />
          </div>
        )
      }
      <div ref={tableRef} className="table">
        <div className="table__container">
          <div className="table__header">
            {
              columns.map(({ label, name, sortable, width, sticky }) => (
                <button
                  key={name}
                  style={{ width, minWidth: width }}
                  type="button"
                  className={`table__header-text${sticky ? " table__sticky-column" : ""}`}
                  onClick={() => handleSortable(name)}
                  disabled={!sortable}
                >
                  {label}
                </button>
              ))
            }
          </div>
          <div className="table__content">
            <div
              className="table__loading"
              style={!loading ? { display: "none" } : { display: "flex" }}
            >
              <div className="lds-roller">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
            {
              !pagination
                ? newData.map(renderData)
                : dataWithPagination().map(renderData)
            }
          </div>
        </div>
      </div>
      {
        pagination && (
          <div className="table__pagination">
            <div className="table__pagination-texts">
              <p className="table__pagination-text">Show</p>
              <select
                onChange={(event) => handleChangeShowItems(event, pageState.currentPage)}
                className="table__pagination-select"
              >
                <option value={10} className="table__pagination-option">
                  {renderOptionPagination(10)}
                </option>
                <option value={20} className="table__pagination-option">
                  {renderOptionPagination(20)}
                </option>
                <option value={50} className="table__pagination-option">
                  {renderOptionPagination(50)}
                </option>
              </select>
              <p className="table__pagination-text">{`of ${pageState.totalItems} items`}</p>
            </div>
            <div className="table__pagination-numbers">
              {
                pageState.getPages().prev ? (
                  <button
                    onClick={() => handleGoToPage(pageState.getPages().prev)}
                    type="button"
                    className="table__pagination-number table__pagination-number--arrow"
                  >
                    <img src={PrevIcon} alt="Prev" className="table__pagination-icon" />
                  </button>
                ) : <span className="table__pagination-number table__pagination-number--disabled" />
              }
              {
                pageState.getPages().prevNeighbour2 ? (
                  <button
                    onClick={() => handleGoToPage(pageState.getPages().prevNeighbour2)}
                    type="button"
                    className="table__pagination-number"
                  >
                    {pageState.getPages().prevNeighbour2}
                  </button>
                ) : <span className="table__pagination-number table__pagination-number--disabled" />
              }
              {
                pageState.getPages().prevNeighbour1 ? (
                  <button
                    onClick={() => handleGoToPage(pageState.getPages().prevNeighbour1)}
                    type="button"
                    className="table__pagination-number"
                  >
                    {pageState.getPages().prevNeighbour1}
                  </button>
                ) : <span className="table__pagination-number table__pagination-number--disabled" />
              }
              <p className="table__pagination-number table__pagination-number--current">
                {pageState.getPages().current}
              </p>
              {
                pageState.getPages().nextNeighbour1 ? (
                  <button
                    onClick={() => handleGoToPage(pageState.getPages().nextNeighbour1)}
                    type="button"
                    className="table__pagination-number"
                  >
                    {pageState.getPages().nextNeighbour1}
                  </button>
                ) : <span className="table__pagination-number table__pagination-number--disabled" />
              }
              {
                pageState.getPages().nextNeighbour2 ? (
                  <button
                    onClick={() => handleGoToPage(pageState.getPages().nextNeighbour2)}
                    type="button"
                    className="table__pagination-number"
                  >
                    {pageState.getPages().nextNeighbour2}
                  </button>
                ) : <span className="table__pagination-number table__pagination-number--disabled" />
              }
              {
                pageState.getPages().next ? (
                  <button
                    onClick={() => handleGoToPage(pageState.getPages().next)}
                    type="button"
                    className="table__pagination-number  table__pagination-number--arrow"
                  >
                    <img src={NextIcon} alt="Next" className="table__pagination-icon" />
                  </button>
                ) : <span className="table__pagination-number table__pagination-number--disabled" />
              }
            </div>
          </div>
        )
      }
    </>
  );
}
