import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactNotification, { store } from "react-notifications-component";
import swal from "sweetalert";
import { useAppContext } from "@context";
import getBaseApi from "@routes";

import "@stylesPages/Login.scss";

import Logo from "@icons/logo.svg";
import EyeIcon from "@icons/eye.svg";
import Language from "@components/Language";

export default function Index({ history, boot }) {
  // eslint-disable-next-line no-unused-vars
  const [_, dispatch] = useAppContext();
  const { search } = useLocation();
  const token = search.split("token=")[1];
  const [error, setError] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const handleFetch = async (url, config) => {
    try {
      const response = await fetch(`${getBaseApi()}${url}`, config);
      const data = await response.json();
      if (data.result) {
        let { profileImage } = data.data;
        if (profileImage === "" || profileImage === null) {
          profileImage = "https://webservicesnt.org/apis/epasero/cosmetics/images/user_perfil.svg";
        }
        dispatch({
          type: "LOGIN",
          payload: {
            ...data.data,
            profileImage
          }
        });
      } else if (data.error) {
        setError(data.error);
        store.addNotification({
          title: "Error al iniciar sesion",
          message: "La contrasena o id son incorrectos",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__zoomOut"],
          dismiss: {
            duration: 3000,
            onScreen: false,
            showIcon: true
          }
        });
      }
    } catch (err) {
      swal({
        icon: "error",
        title: "Error",
        text: err.message
      });
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const tpId = e.target[0].value;
    const pass = e.target[1].value;
    handleFetch(`/get/Login?email=${tpId}&password=${pass}`);
  };

  useEffect(() => {
    if (search === "?success") {
      store.addNotification({
        title: "Cuenta Creada",
        message: "Tu cuenta fue creada correctamente",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: false,
          showIcon: true
        }
      });
    }
    if (token) {
      handleFetch(`/check/Login?token=${token}`);
    }
  }, [search]);
  return (
    <>
      <ReactNotification />
      <section className="login">
        <div className="login__container">
          <div className="login__lang">
            <Language />
          </div>
          <a
            className="login__logo-container"
            href="https://adrswap.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Logo} alt="Logo" className="login__logo" />
          </a>
          <h1 className="login__title">{boot.title}</h1>
          <form className="login__form" onSubmit={handleLogin}>
            <label htmlFor="loginEmail" className="login__item">
              <p className="login__text">TPID:</p>
              <input
                data-error={error}
                onBlur={() => setError(false)}
                id="loginEmail"
                type="number"
                className="login__input"
                requerid="true"
              />
            </label>
            <label
              htmlFor="loginPass"
              className="login__item login__item--password"
            >
              <p className="login__text">{boot.password}</p>
              <input
                data-error={error}
                onBlur={() => setError(false)}
                id="loginPass"
                type={showPass ? "text" : "password"}
                className="login__input"
                requerid="true"
              />
              <button
                type="button"
                onClick={() => setShowPass(!showPass)}
                className="login__show-password"
              >
                <img src={EyeIcon} className="login__eye" alt="Password" />
              </button>
            </label>
            <article className="login__item login__item--center">
              <Link className="login__link" to="/forgot">
                {boot.forgot}
              </Link>
            </article>
            <article className="login__item login__item--center">
              <button type="submit" className="login__submit">
                {boot.submit}
              </button>
            </article>
            <article className="login__item login__item--center">
              <p className="login__account">
                {boot.notAccount}
                {"  "}
                <Link className="login__link" to="/register">
                  {boot.register}
                </Link>
              </p>
            </article>
          </form>
        </div>
      </section>
    </>
  );
}
