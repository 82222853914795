import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "@stylesComponents/Layout.scss";

import Header from "@components/Header";
import Footer from "@components/Footer";

export default function Layout({ children, boot }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Header />
      <main className="layout">
        {children}
      </main>
      <Footer boot={boot} />
    </>
  );
}
