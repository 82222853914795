import React, { useEffect, useState } from "react";
import getBaseApi from "@routes";
import { useAppContext } from "@context";
import swal from "sweetalert";
import { formatter } from "@functions";
import "react-pure-modal/dist/react-pure-modal.min.css";
import PureModal from "react-pure-modal";
import CloseIcon from "@icons/close-blue.svg";
import Loader from "@components/Loader";

import "@stylesPages/Funds.scss";

// import OpenLinkIcon from "@icons/open_link_icon.svg";
import AddIcon from "@icons/add_icon.svg";
// import SrPagosLogo from "@icons/srpagos_logo.svg";
// import PayRetailersLogo from "@images/pay_retailers_logo.png";
import Feenicia from "@images/Feenicia.png";
import Paycips from "@images/Paycips.png";

import Hero from "@components/Hero";
import Card from "@components/Card";
import IconClose from "@icons/close-card-form.svg";
// import { Link } from "react-router-dom";

const initialCard = {
  id: null,
  holder: "",
  number: "",
  date: "",
  code: ""
};

export default function Funds({ boot, history }) {
  const [tabActive, setTabActive] = useState({
    label: boot.deposit.title,
    id: 0
  });
  const [{ user, sessionId }, dispatch] = useAppContext();
  const [{ show, card }, setCardForm] = useState({
    show: false,
    card: initialCard
  });
  const [dataCards, setDataCards] = useState([]);
  const [errorInput, setErrorInput] = useState([]);
  const [paymentConfig, setPaymentConfig] = useState([]);
  const [customPrice, setCustomPrice] = useState({
    amount: 1,
    amountFormat: "1.00",
    currency: "USD"
  });
  const minAmount = "100";
  const [paymentForm, setPaymentForm] = useState({
    amount: user.minAmount ? user.minAmount : minAmount,
    currency: "USD"
  });
  const [paymentMethod, setPaymentMethod] = useState("");
  const [modal, setModal] = useState(false);
  const [linksPaycips, setLinksPaycips] = useState([]);
  const [paycipsSPEI, setPaycipsSPEI] = useState("");
  const [loader, setLoader] = useState(false);
  const [channels, setChannels] = useState([]);
  const [methods, setMethods] = useState([]);
  const [currencyMethod, setCurrencyMethod] = useState([]);
  const [channelMethod, setChannelMethod] = useState([]);
  const pspImages = {
    Feenicia: Feenicia,
    Paycips: Paycips
  };
  const [pspData, setpspData] = useState({
    name: ""
  });
  const [paymentUrl, setPaymentUrl] = useState(null);
  // const getAllCards = () => {
  //   fetch(`${getBaseApi()}/manage/Card?action=All&ID=${user.oyapHSrXRL}`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${sessionId}`
  //     }
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       if (response.result) {
  //         setDataCards(response.data);
  //       }
  //     });
  // };
  const handleChangeTab = (tab) => {
    setTabActive(tab);
  };

  const handleViewForm = (newShow, data) => () => {
    setCardForm({ show: newShow, card: data });
  };
  // const handleChangeCard = (event) => {
  //   setCardForm({
  //     show,
  //     card: {
  //       ...card,
  //       [event.target.name]:
  //         event.target.name === "date"
  //         && event.target.value.length === 3
  //         && event.target.value.slice(-1) !== "/"
  //           ? `${event.target.value.slice(0, 2)}/${event.target.value.slice(
  //             -1
  //           )}` : event.target.value
  //     }
  //   });
  // };
  // const handleAdd = (event) => {
  //   event.preventDefault();
  //   if (errorInput.length > 0) {
  //     swal(boot.alert.card.errorFormat, {
  //       icon: "error"
  //     });
  //   } else {
  //     const dataDate = event.target[2].value.split("/");
  //     const data = {
  //       holderName: event.target[0].value,
  //       cardNumber: event.target[1].value,
  //       expMonth: dataDate[0],
  //       expYear: dataDate[1],
  //       cvv: event.target[3].value,
  //       crmId: user.oyapHSrXRL
  //     };
  //     fetch(`${getBaseApi()}/manage/Card`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${sessionId}`
  //       },
  //       body: JSON.stringify(data)
  //     })
  //       .then((res) => res.json())
  //       .then((response) => {
  //         if (response.result) {
  //           swal(boot.alert.card.addedCard, {
  //             icon: "success"
  //           });
  //           setCardForm({ show: false, card: initialCard });
  //           // getAllCards();
  //         } else {
  //           swal(boot.alert.card.errorAdd, {
  //             icon: "error"
  //           });
  //         }
  //       });
  //   }
  // };
  // const handleDeleteCard = (id) => () => {
  //   swal({
  //     title: boot.alert.card.confirmDeleteTitle,
  //     text: boot.alert.card.confirmDeleteBody,
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       fetch(`${getBaseApi()}/manage/Card`, {
  //         method: "DELETE",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${sessionId}`
  //         },
  //         body: JSON.stringify({ idCard: id })
  //       })
  //         .then((res) => res.json())
  //         .then((response) => {
  //           if (response.result) {
  //             swal(boot.alert.card.delete, {
  //               icon: "success"
  //             });
  //             // getAllCards();
  //           }
  //         });
  //     } else {
  //       swal(boot.alert.card.cancelDelete);
  //     }
  //   });
  // };
  // const handleModifyCard = (event) => {
  //   event.preventDefault();
  //   if (errorInput.length > 0) {
  //     swal(boot.alert.card.errorFormat, {
  //       icon: "error"
  //     });
  //   } else {
  //     const data = card.date.split("/");
  //     const dataCard = {
  //       holderName: card.holder,
  //       cardNumber: card.number,
  //       expMonth: data[0],
  //       expYear: data[1],
  //       cvv: card.code,
  //       crmId: user.oyapHSrXRL,
  //       idCard: card.id
  //     };
  //     fetch(`${getBaseApi()}/manage/Card`, {
  //       method: "PUT",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${sessionId}`
  //       },
  //       body: JSON.stringify(dataCard)
  //     })
  //       .then((res) => res.json())
  //       .then((response) => {
  //         if (response.result) {
  //           swal(boot.alert.card.updatedCard, {
  //             icon: "success"
  //           });
  //           setCardForm({ show: false, card: initialCard });
  //           // getAllCards();
  //         }
  //       });
  //   }
  // };
  const handleWithdrawal = async (event) => {
    event.preventDefault();
    const data = JSON.stringify({
      amount: parseFloat(event.target[1].value),
      currency: event.target[0].value,
      comment: event.target[2].value
    });
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionId}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: data,
      redirect: "follow"
    };
    const response = await fetch(
      `${getBaseApi()}/manage/Transaction`,
      requestOptions
    );
    const { result, error, data: dataResponse } = await response.json();
    if (result) {
      swal(boot.alert.withdrawal.success, {
        icon: "success"
      });
    } else if (error === "EWNR") {
      swal(boot.alert.withdrawal.errorTime, {
        icon: "error"
      });
    } else if (error === "EANA") {
      swal(boot.alert.withdrawal.errorAmount, {
        icon: "error"
      });
    } else if (error === "EDNE") {
      swal(boot.alert.withdrawal.errorFunds, {
        icon: "error"
      });
    }
  };
  const validNumbers = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/[0-9]/.test(keyValue)) {
      event.preventDefault();
    }
  };
  const validExpNumbers = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/[0-9/]/.test(keyValue)) {
      event.preventDefault();
    }
  };
  const handleBlur = (event) => {
    switch (event.target.name) {
      case "code":
        setErrorInput(
          !event.target.value.match(/[0-9]{3,4}/)
            || event.target.value.match(/[0]{3,4}/)
            ? [...errorInput, "code"]
            : errorInput.filter((input) => input !== "code")
        );
        break;
      case "date":
        setErrorInput(
          !event.target.value.match(/[0-9]{2}[/][2-3]{1}[0-9]{1}/)
            ? [...errorInput, "date"]
            : errorInput.filter((input) => input !== "date")
        );
        break;
      case "number":
        setErrorInput(
          !event.target.value.match(/[0-9]{12,19}/)
            ? [...errorInput, "number"]
            : errorInput.filter((input) => input !== "number")
        );
        break;
      default:
        break;
    }
  };
  const handlepayment = async (e) => {
    e.preventDefault();
    setLoader(true);
    let body = {
      ...paymentForm,
      tp_id: user.tpId,
      psp: pspData.pspId,
      country: user.country,
      action: paymentMethod
    };
    if (pspData.name === "Fairpay") {
      body = {
        ...body,
        channel: channelMethod,
        uid: paymentMethod
      };
      // console.log(body);
      // setLoader(false);
      const resFairpay = await fetch(
        "https://clientzone.adrswap.com/api/transaction/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionId}`
          },
          body: JSON.stringify(body)
        }
      );
      const { result, data, error } = await resFairpay.json();
      setLoader(false);
      // console.log(result,data, error)
      if (result) {
        if (result) {
          setPaymentUrl(data);
          // const a = document.createElement("a");
          // a.setAttribute("href", data);
          // a.setAttribute("target", "_blank");
          // a.click();
        }
        // history.push("/");
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: error
        });
      }
    }
  };

  const handlePaymentChange = (e) => {
    setPaymentForm({ ...paymentForm, [e.target.name]: e.target.value });
  };

  const handleChannel = (event) => {
    const channelName = event.target.id;
    const method = pspData.method[user.country].filter((item) => item.channel === channelName);
    // console.log(method.map((item) => ({ key: item.uid, value: item.name })));
    setMethods(method);
    setChannelMethod(channelName);
    setCurrencyMethod([]);
  };

  const handleCurrency = (event) => {
    const type = event.target.value;
    const method = pspData.method[user.country].filter((item) => item.uid === type)[0];
    setCurrencyMethod(method.currencies.map((item) => ({ label: item, value: item })));
    setPaymentMethod(type);
    // console.log(paymentMethod);
  };

  const resetPayment = () => {
    setPaymentUrl(null);
  };

  // const changeRadioBtn = (e) => {
  //   setpspData({
  //     ...paymentConfig.filter((psp) => psp.name === e.target.id)[0]
  //   });
  // };
  useEffect(() => {
    fetch(
      `https://clientzone.adrswap.com/api/exchange/?amount=${paymentForm.amount}&currency=${paymentForm.currency}`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setCustomPrice({
            amount: response.data.amount,
            amountFormat: formatter.format(response.data.amount),
            currency: response.data.currency
          });
        }
      });
  }, [paymentForm.currency, paymentForm.amount]);

  useEffect(async () => {
    // getAllCards();
    setLoader(true);
    const response = await fetch(
      `https://clientzone.adrswap.com/api/config.php?tpId=${user.tpId}`
    );
    const data = await response.json();
    const psps = data.filter((psp) => psp.available);
    setPaymentConfig(psps);
    setpspData({
      ...psps[0]
    });

    if (psps.length > 0) {
      const { method } = psps[0];
      const getChannels = method[user.country].map((item) => item.channel);
      const uniqueChannels = getChannels.filter((item, index) => getChannels.indexOf(item) === index);
      setChannels(uniqueChannels);
    }
    setLoader(false);
  }, []);

  return (
    <>
      {loader && <Loader />}
      <section className="funds">
        <Hero
          title={boot.title}
          tabs={[
            {
              label: boot.deposit.title,
              id: 0
            },
            {
              label: boot.extract.title,
              id: 1
            }
          ]}
          activeTab={tabActive}
          changeTab={handleChangeTab}
        />
        {tabActive.id === 0 ? (
          <>
            {/* <div className="funds__deposit">
              <h2 className="funds__title">{boot.deposit.title}</h2>
              <div className="funds__account">
                <div className="funds__account-content">
                  <label
                      data-text={boot.label}
                      htmlFor="accountNumber"
                      className="funds__label funds__label--nowidth"
                      style={{ visibility: "hidden" }}
                    >
                      <select name="accountNumber" id="accountNumber" className="funds__input funds__input--nowidth">
                        <option value="1300839548">1300839548</option>
                      </select>
                    </label>
                </div>
              </div>
            </div> */}
            {/* <div className="funds__cards">
              <h2 className="funds__subtitle">{boot.deposit.section1Title}</h2>
              <div className="funds__cards-content">
                {dataCards.length ? (
                  dataCards.map((item) => (
                    <Card
                      key={item.id}
                      name={item.id}
                      data={{
                        id: item.id,
                        holder: item.holderName,
                        number: item.cardNumber,
                        dateCard: `${item.expMonth}/${item.expYear}`,
                        codeCard: item.cvv
                      }}
                      onEdit={handleViewForm}
                      // onDelete={handleDeleteCard}
                    />
                  ))
                ) : (
                  <h3>No cuentas con tarjetas</h3>
                )}
              </div>
            </div> */}
            {/* <div className="funds__cards-forms">
              <button
                onClick={handleViewForm(true, initialCard)}
                type="button"
                className="funds__button funds__button--new"
              >
                <img
                  src={AddIcon}
                  alt="Add card"
                  className="funds__button-icon"
                />
                {boot.deposit.add}
              </button>
              {show && (
                <div className="funds__cards-modify">
                  <h2 className="funds__subtitle">
                    {card.id
                      ? boot.deposit.section2TitleEdit
                      : boot.deposit.section2Title}
                  </h2>
                  <form
                    // onSubmit={card.id ? handleModifyCard : handleAdd}
                    className="funds__cards-form"
                  >
                    <div className="funds__cards-grid">
                      <label
                        data-text={boot.deposit.label1}
                        htmlFor="holder"
                        className="funds__label"
                      >
                        <input
                          // onChange={handleChangeCard}
                          value={card.holder}
                          type="text"
                          name="holder"
                          id="holder"
                          className="funds__input"
                          required
                        />
                      </label>
                      <label
                        data-text={boot.deposit.label2}
                        htmlFor="number"
                        className="funds__label"
                      >
                        <input
                          // onChange={handleChangeCard}
                          value={card.number}
                          type="text"
                          name="number"
                          onKeyPress={validNumbers}
                          id="number"
                          onBlur={handleBlur}
                          className={`funds__input ${
                            errorInput.includes("number")
                              ? " register__input--error"
                              : ""
                          }`}
                          required
                        />
                        {errorInput.includes("number") ? (
                          <span className="">{boot.deposit.errorFormat}</span>
                        ) : null}
                      </label>
                      <label
                        data-text={boot.deposit.label3}
                        htmlFor="date"
                        className="funds__label"
                      >
                        <input
                          // onChange={handleChangeCard}
                          value={card.date}
                          type="text"
                          name="date"
                          onKeyPress={validExpNumbers}
                          maxLength="5"
                          id="date"
                          onBlur={handleBlur}
                          className={`funds__input ${
                            errorInput.includes("date")
                              ? " register__input--error"
                              : ""
                          }`}
                          required
                        />
                        {errorInput.includes("date") ? (
                          <span className="">{boot.deposit.errorFormat}</span>
                        ) : null}
                      </label>
                      <label
                        data-text={boot.deposit.label4}
                        htmlFor="code"
                        className="funds__label"
                      >
                        <input
                          // onChange={handleChangeCard}
                          value={card.code}
                          type="text"
                          name="code"
                          id="code"
                          // onKeyPress={validExpNumbers}
                          maxLength="4"
                          onBlur={handleBlur}
                          className={`funds__input ${
                            errorInput.includes("code")
                              ? " register__input--error"
                              : ""
                          }`}
                          autoComplete="off"
                          required
                        />
                        {errorInput.includes("code") ? (
                          <span className="">{boot.deposit.errorFormat}</span>
                        ) : null}
                      </label>
                    </div>
                    <div className="funds__cards-actions">
                      <button
                        onClick={handleViewForm(false, initialCard)}
                        type="button"
                        className="funds__button funds__button--cancel"
                      >
                        {boot.deposit.cancel}
                      </button>
                      <button type="submit" className="funds__button">
                        {boot.deposit.save}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div> */}
            <div className="funds__deposit-deposit">
              <h2 className="funds__title">{boot.deposit.title}</h2>
              <div className="funds__deposit-options">
                {
                  (paymentConfig.length > 0) ? channels.map((channel) => (
                    <label
                      key={channel}
                      htmlFor={channel}
                      className="funds__deposit-option"
                    >
                      <input
                        type="radio"
                        id={channel}
                        name="psp"
                        onChange={handleChannel}
                        className="funds__deposit-option-input"
                      />
                      <p className="funds__deposit-option-text">{channel}</p>
                    </label>
                  )) : <h2>No hay procesador de Pagos</h2>
                }
              </div>
              {pspData.name === "Fairpay" ? (
                <>
                  <form
                    className="funds__deposit-amount"
                    onSubmit={handlepayment}
                  >
                    <div className="funds__deposit-amount-section">
                      <label
                        data-text={boot.deposit.label5}
                        htmlFor="amount"
                        className="funds__label funds__label--margin"
                      >
                        <input
                          type="number"
                          name="amount"
                          id="amount"
                          className="funds__input"
                          value={paymentForm.amount}
                          onChange={handlePaymentChange}
                          min={1}
                          max={250}
                          step="0.01"
                          required
                        />
                      </label>
                      <label
                        data-text="Metodo"
                        htmlFor="amount"
                        className="funds__label funds__label--margin"
                      >
                        <select
                          name="action"
                          id="action"
                          required
                          className="funds__input"
                          defaultValue=""
                          onChange={handleCurrency}
                        >
                          <option hidden>Seleccione</option>
                          {methods.map(
                            ({ uid, name }) => (
                              <option key={uid} value={uid}>
                                {name}
                              </option>
                            )
                          )}
                        </select>
                      </label>
                      <label
                        data-text="Currency"
                        htmlFor="currency"
                        className="funds__label funds__label--margin"
                      >
                        <select
                          name="currency"
                          id="currency"
                          className="funds__input"
                          required
                          value={paymentForm.currency}
                          onChange={handlePaymentChange}
                        >
                          <option value="" hidden>
                            Currency
                          </option>
                          {currencyMethod.map((item) => (
                            <option key={item.label} value={item.value}>
                              {item.value}
                            </option>
                          ))}
                        </select>
                      </label>
                      {user.country === "BR" && (
                      <>
                        <label
                          data-text="Documento nacional de identidad"
                          htmlFor="bank"
                          className="funds__label funds__label--margin"
                        >
                          <input
                            type="text"
                            name="document"
                            id="document"
                            className="funds__input"
                            onChange={handlePaymentChange}
                            required
                          />
                        </label>
                        <label
                          data-text="Ciudad"
                          htmlFor="bank"
                          className="funds__label funds__label--margin"
                        >
                          <input
                            type="text"
                            name="city"
                            id="city"
                            className="funds__input"
                            onChange={handlePaymentChange}
                            required
                          />
                        </label>
                        <label
                          data-text="Dirección"
                          htmlFor="bank"
                          className="funds__label funds__label--margin"
                        >
                          <input
                            type="text"
                            name="address"
                            id="address"
                            className="funds__input"
                            onChange={handlePaymentChange}
                            required
                          />
                        </label>
                        <label
                          data-text="Codigo postal"
                          htmlFor="bank"
                          className="funds__label funds__label--margin"
                        >
                          <input
                            type="text"
                            name="postal"
                            id="postal"
                            className="funds__input"
                            onChange={handlePaymentChange}
                            required
                          />
                        </label>
                        <label
                          data-text="Teléfono"
                          htmlFor="bank"
                          className="funds__label funds__label--margin"
                        >
                          <input
                            type="number"
                            name="phone"
                            id="phone"
                            className="funds__input"
                            onChange={handlePaymentChange}
                            required
                          />
                        </label>
                      </>
                      )}
                    </div>
                    <div className="funds__deposit-amount-section">
                      <div className="funds__deposit-card">
                        <h3 className="funds__deposit-card-title">
                          {boot.deposit.amountTitle}
                          <span className="funds__deposit-card-total">
                            {`${customPrice.amountFormat} ${customPrice.currency}`}
                          </span>
                        </h3>
                        <button
                          className="funds__deposit-card-submit"
                          type="submit"
                        >
                          {boot.deposit.title}
                        </button>
                        <span className="funds__deposit-card-legend">
                          {boot.deposit.legend}
                          <a
                            href="https://adrswap.com/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="funds__deposit-card-legend-link"
                          >
                            {` ${boot.deposit.conditions}`}
                          </a>
                        </span>
                      </div>
                    </div>
                  </form>
                  {
                    paymentUrl && (
                    <div className="psps__url-modal">
                      <button type="button" className="psps__form-close" onClick={resetPayment}>
                        <img src={IconClose} alt="" className="psps__form-close-icon" />
                      </button>
                      <iframe
                        className="psps__url-modal-iframe"
                        id="payment-url"
                        title="Payment url"
                        loading="lazy"
                        src={paymentUrl}
                      >
                      </iframe>
                    </div>
                    )
                  }
                </>
              ) : null}
            </div>
          </>
        ) : null}
        {tabActive.id === 1 ? (
          <div className="funds__extract">
            <h2 className="funds__title">{boot.extract.title}</h2>
            <div className="funds__account">
              {/* <h2 className="funds__subtitle">{boot.sectionTitle}</h2> */}
              {/* <label data-text={boot.label} htmlFor="accountNumber" className="funds__label funds__label--nowidth">
                  <select name="accountNumber" id="accountNumber" className="funds__input funds__input--nowidth">
                    <option value="1300839548">1300839548</option>
                    <option value="1300839548">1300839548</option>
                    <option value="1300839548">1300839548</option>
                  </select>
                </label> */}
            </div>
            <div className="funds__reasons">
              <h2 className="funds__subtitle">{boot.extract.sectionTitle}</h2>
              <form className="funds__reasons-form" onSubmit={handleWithdrawal}>
                <div className="funds__grid">
                  <label
                    data-text={boot.extract.label1}
                    htmlFor="currency"
                    className="funds__label"
                  >
                    <select
                      name="currency"
                      id="currency"
                      className="funds__input"
                    >
                      <option value="USD">USD</option>
                    </select>
                  </label>
                  <label
                    data-text={boot.extract.label2}
                    htmlFor="amount"
                    className="funds__label"
                  >
                    <input
                      type="text"
                      name="amount"
                      id="amount"
                      className="funds__input"
                      onKeyPress={validNumbers}
                      required
                    />
                  </label>
                  <label
                    data-text={boot.extract.label3}
                    htmlFor="reason"
                    className="funds__label"
                  >
                    <input
                      type="text"
                      name="reason"
                      id="reason"
                      className="funds__input"
                      required
                    />
                  </label>
                </div>
                <button type="submit" className="funds__button">
                  {boot.extract.submit}
                </button>
              </form>
            </div>
          </div>
        ) : null}
      </section>
      <PureModal
        isOpen={modal}
        header={
          paymentMethod === "spei" ? (
            <div
              className="payment__modal-header"
              style={{ textAlign: "center" }}
            >
            </div>
          ) : (
            <div className="payment__modal-header">Link de pago</div>
          )
        }
        closeButton={(
          <button type="button">
            <img src={CloseIcon} alt="Close" />
          </button>
        )}
        className="smallModal"
        onClose={() => {
          setModal(false);
          return true;
        }}
      >
        <div className="funds__modal">
          {paymentMethod === "cash"
            ? linksPaycips.map((link) => (
              link.URL !== "" && link.URL ? (
                <button
                  type="button"
                  className="funds__modal-link-pago"
                  onClick={() => {
                    setModal(false);
                    setLinksPaycips([]);
                    const a = document.createElement("a");
                    a.setAttribute("href", link.URL);
                    a.setAttribute("target", "_blank");
                    a.click();
                    history.push("/");
                  }}
                >
                  {link.Description}
                </button>
              ) : null
            )) : null}
          {paymentMethod === "spei" ? (
            <>
              <div>
                <img src={paycipsSPEI} alt="" style={{ width: "100%" }} />
              </div>
              <button
                type="button"
                className="funds__modal-link-pago"
                onClick={() => {
                  const imgUrl = paycipsSPEI.replace("view", "download");
                  const a = document.createElement("a");
                  a.setAttribute("href", imgUrl);
                  a.click();
                }}
              >
                Descargar
              </button>
              <button
                type="button"
                className="funds__modal-link-pago"
                onClick={() => {
                  setModal(false);
                  localStorage.removeItem("cart");
                  history.push("/");
                }}
              >
                Cerrar
              </button>
            </>
          ) : null}
        </div>
      </PureModal>
    </>
  );
}
