import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "@context";

import "@stylesComponents/Footer.scss";

import Logo from "@icons/logo.svg";

export default function Footer() {
  const [
    {
      boot: { footer: boot }
    }
  ] = useAppContext();

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__box">
          <img src={Logo} alt="Logo" className="footer__logo" />
          <p className="footer__text">{boot.copyright}</p>
        </div>
        <div className="footer__box">
          <ul className="footer__list">
            <li className="footer__item">
              <a
                href="https://adrswap.com/about"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__link"
              >
                {boot.title3}
              </a>
            </li>
            <li className="footer__item">
              <Link to="/platform" className="footer__link">
                {boot.title4}
              </Link>
              <a
                href="https://adrswap.tradingweb.io/"
                target="_blank"
                rel="noreferrer"
                className="footer__link footer__link--small"
              >
                Sirix Web Trader
              </a>
            </li>
            {/* <li className="footer__item">
              <Link to="/education" className="footer__link">
                {boot.title5}
              </Link>
            </li> */}
            <li className="footer__item">
              <a
                href="https://adrswap.com/market"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__link"
              >
                {boot.title6}
              </a>
            </li>
            {/* <li className="footer__item">
              <a
                href="http://support.adrswap.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__link"
              >
                {boot.title7}
              </a>
            </li> */}
          </ul>
          <ul className="footer__list">
            <li className="footer__item">
              <a
                href="https://adrswap.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__link"
              >
                {boot.text2}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
