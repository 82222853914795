import getBoot from "@boot";

export default function reducer(state, action) {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      const langValidation = state.lang === "en" ? "es" : "en";
      localStorage.setItem("brandlang", langValidation);
      return {
        ...state,
        boot: getBoot()[langValidation],
        lang: langValidation
      };
    case "LOGIN":
      const { sessionId, ...user } = action.payload;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("sessionId", sessionId);
      return {
        ...state,
        user,
        sessionId
      };
    case "LOGOUT":
      localStorage.removeItem("user");
      localStorage.removeItem("sessionId");
      return {
        ...state,
        user: {},
        sessionId: null
      };
    case "CHANGEIMAGE":
      const newUser = { ...state.user, profileImage: `${action.payload}?${Math.floor(Math.random() * 100) + 1}` };
      localStorage.setItem("user", JSON.stringify(newUser));
      return {
        ...state,
        user: newUser
      };
    default:
      return state;
  }
}
