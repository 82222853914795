import React from "react";
import ReactNotification, { store } from "react-notifications-component";
import getBaseApi from "@routes";

import "@stylesPages/Forgot.scss";
// Assets
import Logo from "@icons/logo.svg";

import Language from "@components/Language";

export default function Forgot({ boot, history }) {
  const resetPass = (e) => {
    e.preventDefault();
    const tpid = e.target[0].value;
    document
      .getElementById("submitButton")
      .setAttribute("disabled", "disabled");
    fetch(`${getBaseApi()}/resetPassword?action=Reset&tpId=${tpid}`)
      .then((res) => res.json())
      .then((response) => {
        document.getElementById("submitButton").removeAttribute("disabled");
        if (response.result) {
          store.addNotification({
            title: "Listo",
            message: "Tu nueva contraseña temporal ha sido enviada a tu correo",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__zoomOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
              showIcon: true
            }
          });
          document.getElementById("forgotid").value = "";
          history.pushState("/login");
        } else {
          store.addNotification({
            title: "Lo sentimos",
            message: "Parece que el usuario no existe. Intenta nuevamente.",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__zoomOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
              showIcon: true
            }
          });
        }
      });
  };
  return (
    <>
      <ReactNotification />
      <section className="forgot">
        <div className="forgot__container">
          <div className="forgot__lang">
            <Language />
          </div>
          <a
            className="forgot__logo-container"
            href="https://adrswap.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Logo} alt="Logo" className="forgot__logo" />
          </a>
          <form className="forgot__form" onSubmit={(e) => resetPass(e)}>
            <label htmlFor="forgotEmail" className="forgot__item">
              <p className="forgot__text">ID:</p>
              <input id="forgotid" type="text" className="forgot__input" />
            </label>
            <article className="forgot__item forgot__item--center">
              <button
                id="submitButton"
                type="submit"
                className="forgot__submit"
              >
                {boot.submit}
              </button>
            </article>
          </form>
        </div>
      </section>
    </>
  );
}
