import React from "react";
import ReactNotification, { store } from "react-notifications-component";
import getBaseApi from "@routes";
import { useAppContext } from "@context";
import "@stylesPages/Platform.scss";

import Hero from "@components/Hero";

export default function Platform({ boot }) {
  const [{ user, sessionId }, dispatch] = useAppContext();

  const getSirix = async () => {
    const reponse = await fetch(`${getBaseApi()}/manage/Customer?action=Sirix`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionId}`
      }
    });
    const { data, error, result } = await reponse.json();
    if (result) {
      window.location.replace(data.autologinUrl);
    } else if (error === "EISN") {
      store.addNotification({
        title: "Error",
        message: boot.alertErrorSirix,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__zoomOut"],
        dismiss: {
          duration: 3000,
          onScreen: false,
          showIcon: true
        }
      });
    } else {
      store.addNotification({
        title: "Error",
        message: boot.alertErrorSirixGeneral,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__zoomOut"],
        dismiss: {
          duration: 3000,
          onScreen: false,
          showIcon: true
        }
      });
    }
  };
  return (
    <>
      <ReactNotification />
      <section className="platform">
        <Hero
          title={boot.title}
        />
        <div className="platform__sirix">
          <h2 className="platform__sirix-title">{boot.section1Title}</h2>
          <div className="platform__sirix-content">
            {/* <label data-text={boot.label} htmlFor="accountNumber" className="platform__label">
              <select name="accountNumber" id="accountNumber" className="platform__input">
                <option value="1300839548">1300839548</option>
              </select>
            </label> */}
            <button type="button" className="platform__button" onClick={getSirix}>{boot.button1}</button>
          </div>
        </div>
        <div className="platform__content">
          <h3 className="platform__subtitle">{boot.section3Title}</h3>
          <div className="platform__download">
            <div className="platform__download-box">
              <p className="platform__download-text">{boot.download2}</p>
              <a
                href="https://anydesk.com/es/downloads/windows"
                target="_blank"
                rel="noopener noreferrer"
                className="platform__button"
              >
                {boot.button2}
              </a>
            </div>
            <div className="platform__download-box">
              <p className="platform__download-text">{boot.download3}</p>
              <a
                href="https://anydesk.com/es/downloads/android"
                target="_blank"
                rel="noopener noreferrer"
                className="platform__button"
              >
                {boot.button2}
              </a>
            </div>
          </div>
          <div className="platform__download">
            <div className="platform__download-box">
              <p className="platform__download-text">{boot.download4}</p>
              <a href="https://anydesk.com/es/downloads/ios" target="_blank" rel="noopener noreferrer" className="platform__button">
                {boot.button2}
              </a>
            </div>
            <div className="platform__download-box">
              <p className="platform__download-text">{boot.download5}</p>
              <a href="https://anydesk.com/es/downloads/mac-os" target="_blank" rel="noopener noreferrer" className="platform__button">
                {boot.button2}
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
