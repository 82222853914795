// Converts an object to a query
export default function convertToQuery(obj) {
  const keys = Object.keys(obj);
  let query = "?";
  keys.forEach((item) => {
    if (typeof obj[item] === "string") {
      query += `${item}=${obj[item]}&`;
    } else if (Array.isArray(obj[item])) {
      if (!Number.isNaN(parseInt(obj[item][0], 10))) {
        query += `${item}=[`;
        obj[item].forEach((value) => {
          query += `${value},`;
        });
        query = `${query.substring(0, query.length - 1)}]&`;
      } else if (typeof obj[item][0] === "string") {
        query += `${item}=[`;
        obj[item].forEach((value) => {
          query += `"${value}",`;
        });
        query = `${query.substring(0, query.length - 1)}]&`;
      }
    } else if (typeof obj[item] === "number") {
      query += `${item}=${obj[item]}&`;
    }
  });
  return query.substring(0, query.length - 1);
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2
  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
