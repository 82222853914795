import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation, Link } from "react-router-dom";
import { useAppContext } from "@context";

import "@stylesComponents/Header.scss";

import Logo from "@icons/logo.svg";
import LangIcon from "@icons/lang_icon.svg";
import DefaultPhoto from "@images/default_photo.png";
import MenuIcon from "@icons/menu_icon.svg";
import MenuCloseIcon from "@icons/menu_close_icon.svg";
import Language from "./Language";

export default function Header() {
  const [
    {
      user,
      boot: { header: boot }
    },
    dispatch
  ] = useAppContext();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [showActions, setShowActions] = useState(false);
  const [mobile, setMobile] = useState(false);

  const userPhoto = user.profileImage !== "" ? user.profileImage : DefaultPhoto;
  const loginValidation = pathname !== "/login" && pathname !== "/register" && pathname !== "/forgot";

  const handleLang = () => {
    dispatch({ type: "CHANGE_LANGUAGE" });
  };

  const handleUserActions = () => {
    setShowActions(!showActions);
  };

  const handleProfile = () => {
    push("/profile");
    setShowActions(!showActions);
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    setMobile(false);
  }, [pathname]);

  return (
    <>
      {loginValidation && (
        <>
          <header className="header">
            <Link to="/" className="header__logo">
              <img src={Logo} alt="Logo" className="header__logo-image" />
            </Link>
            <nav className="header__menu">
              <ul className="header__menu-content">
                <li className="header__menu-item">
                  <NavLink
                    exact
                    to="/account"
                    className="header__menu-link"
                    activeClassName="header__menu-link--active"
                  >
                    <p className="header__menu-text">{boot.link1}</p>
                  </NavLink>
                </li>
                <li className="header__menu-item">
                  <NavLink
                    exact
                    to="/funds"
                    className="header__menu-link"
                    activeClassName="header__menu-link--active"
                  >
                    <p className="header__menu-text">{boot.link2}</p>
                  </NavLink>
                </li>
                <li className="header__menu-item">
                  <NavLink
                    exact
                    to="/platform"
                    className="header__menu-link"
                    activeClassName="header__menu-link--active"
                  >
                    <p className="header__menu-text">{boot.link3}</p>
                  </NavLink>
                </li>
                <li className="header__menu-item">
                  <NavLink
                    exact
                    to="/support"
                    className="header__menu-link"
                    activeClassName="header__menu-link--active"
                  >
                    <p className="header__menu-text">{boot.link4}</p>
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className="header__user">
              <div
                data-show={showActions}
                className="header__user-content"
                onClick={handleUserActions}
                role="button"
                tabIndex={0}
              >
                <img
                  src={userPhoto}
                  alt="User"
                  className="header__user-image"
                />
                <p className="header__user-name">
                  {`${boot.hello}, ${user.firstName}`}
                </p>
                {showActions && (
                  <div className="header__user-actions">
                    <button
                      onClick={handleProfile}
                      type="button"
                      className="header__user-action"
                    >
                      {boot.link5}
                    </button>
                    <button
                      onClick={handleLogout}
                      type="button"
                      className="header__user-action"
                    >
                      {boot.session}
                    </button>
                  </div>
                )}
              </div>
              <Language />
            </div>
            <button
              onClick={() => setMobile(!mobile)}
              type="button"
              className="header__button"
            >
              <img
                src={MenuIcon}
                alt="Open menu"
                className="header__button-icon"
              />
            </button>
            <div
              className={`header__mobile${
                mobile ? " header__mobile--active" : ""
              }`}
            >
              <button
                onClick={() => setMobile(!mobile)}
                type="button"
                className={`header__button header__button--close${
                  mobile ? " header__button--active" : ""
                }`}
              >
                <img
                  src={MenuCloseIcon}
                  alt="Close menu"
                  className="header__button-icon"
                />
              </button>
              <a
                href="https://adrswap.com/"
                className={`header__mobile-logo${
                  mobile ? " header__mobile-logo--active" : ""
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Logo}
                  alt="Logo"
                  className="header__mobile-logo-image"
                />
              </a>
              <nav className="header__mobile-menu">
                <ul className="header__mobile-menu-content">
                  <li className="header__mobile-menu-item">
                    <NavLink
                      exact
                      to="/account"
                      className="header__mobile-menu-link"
                      activeClassName="header__mobile-menu-link--active"
                    >
                      <p className="header__mobile-menu-text">{boot.link1}</p>
                    </NavLink>
                  </li>
                  <li className="header__mobile-menu-item">
                    <NavLink
                      exact
                      to="/funds"
                      className="header__mobile-menu-link"
                      activeClassName="header__mobile-menu-link--active"
                    >
                      <p className="header__mobile-menu-text">{boot.link2}</p>
                    </NavLink>
                  </li>
                  <li className="header__mobile-menu-item">
                    <NavLink
                      exact
                      to="/platform"
                      className="header__mobile-menu-link"
                      activeClassName="header__mobile-menu-link--active"
                    >
                      <p className="header__mobile-menu-text">{boot.link3}</p>
                    </NavLink>
                  </li>
                  <li className="header__mobile-menu-item">
                    <NavLink
                      exact
                      to="/support"
                      className="header__mobile-menu-link"
                      activeClassName="header__mobile-menu-link--active"
                    >
                      <p className="header__mobile-menu-text">{boot.link4}</p>
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className="header__mobile-user">
                <div
                  data-show={showActions}
                  onClick={handleUserActions}
                  className="header__mobile-user-content"
                  role="button"
                  tabIndex={0}
                >
                  <img
                    src={userPhoto}
                    alt="User"
                    className="header__mobile-user-image"
                  />
                  <p className="header__mobile-user-name">
                    {`${boot.hello}, ${user.firstName}`}
                  </p>
                  {showActions && (
                    <div className="header__mobile-user-actions">
                      <button
                        onClick={handleProfile}
                        type="button"
                        className="header__mobile-user-action"
                      >
                        {boot.link5}
                      </button>
                      <button
                        onClick={handleLogout}
                        type="button"
                        className="header__mobile-user-action"
                      >
                        {boot.session}
                      </button>
                    </div>
                  )}
                </div>
                <Language />
              </div>
            </div>
          </header>
        </>
      )}
    </>
  );
}
