import React, { useEffect, useState } from "react";
import ReactNotification, { store } from "react-notifications-component";
import { getCountries } from "@boot";
import { useAppContext } from "@context";
import getBaseApi from "@routes";

import "@stylesPages/Register.scss";
// Assets
import Logo from "@icons/logo.svg";
import EyeIcon from "@icons/eye.svg";

import Language from "@components/Language";

const Loader = ({ message }) => (
  <div className="loader">
    <div className="loader__spinner" />
    <p className="loader__text">{message}</p>
  </div>
);

export default function Register({ history, boot }) {
  const [_, dispatch] = useAppContext();
  const [showPass, setShowPass] = useState(false);
  const [errorInput, setErrorInput] = useState([]);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    country: "",
    phoneCode: "",
    phoneNumber: ""
  });
  const [loader, setLoader] = useState(false);

  const handleInput = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };

  const handleBlur = (event) => {
    switch (event.target.name) {
      case "firstName":
        setErrorInput(
          !form.firstName
            ? [...errorInput, "firstName"]
            : errorInput.filter((input) => input !== "firstName")
        );
        break;
      case "lastName":
        setErrorInput(
          !form.lastName
            ? [...errorInput, "lastName"]
            : errorInput.filter((input) => input !== "lastName")
        );
        break;
      case "email":
        setErrorInput(
          !form.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)
            ? [...errorInput, "email"]
            : errorInput.filter((input) => input !== "email")
        );
        break;
      case "password":
        setErrorInput(
          !form.password.match(/^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/g)
            ? [...errorInput, "password"]
            : errorInput.filter((input) => input !== "password")
        );
        break;
      case "phoneNumber":
        setErrorInput(
          !form.phoneNumber.match(/^[0-9]{10,16}$/g) || !form.phoneNumber
            ? [...errorInput, "phoneNumber"]
            : errorInput.filter((input) => input !== "phoneNumber")
        );
        break;
      default:
        break;
    }
  };

  const registerUser = (e) => {
    e.preventDefault();
    if (form.password.match(/^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/g)) {
      setLoader(`${boot.registering}...`);
      document
        .getElementById("submitButton")
        .setAttribute("disabled", "disabled");
      fetch(`${getBaseApi()}/insertClient`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.result) {
            setLoader("Login...");
            // store.addNotification({
            //   title: "Success",
            //   message: `${boot.registerSuccess}...`,
            //   type: "success",
            //   insert: "top",
            //   container: "top-right",
            //   animationIn: ["animate__animated", "animate__fadeIn"],
            //   animationOut: ["animate__animated", "animate__zoomOut"],
            //   dismiss: {
            //     duration: 2000,
            //     onScreen: false,
            //     showIcon: true
            //   }
            // });
            const response2 = await fetch(
              `${getBaseApi()}/get/Login?email=${form.email}&password=${form.password}`
            );
            const {
              data: dataRes2,
              error: errorRes2,
              result: resultRes2
            } = await response2.json();
            if (resultRes2) {
              setTimeout(() => {
                dispatch({
                  type: "LOGIN",
                  payload: {
                    ...dataRes2,
                    profileImage:
                      "https://webservicesnt.org/apis/epasero/cosmetics/images/user_perfil.svg"
                  }
                });
                setLoader(false);
                history.push("/");
              }, 1300);
            } else {
              store.addNotification({
                title: "Error",
                message: `${errorRes2}...`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__zoomOut"],
                dismiss: {
                  duration: 5000,
                  onScreen: false,
                  showIcon: true
                }
              });
              document
                .getElementById("submitButton")
                .removeAttribute("disabled");
              setLoader(false);
            }
          } else {
            store.addNotification({
              title: "Error",
              message: `${boot.registerError}...`,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__zoomOut"],
              dismiss: {
                duration: 5000,
                onScreen: false,
                showIcon: true
              }
            });
            document.getElementById("submitButton").removeAttribute("disabled");
            setLoader(false);
          }
        });
    } else {
      store.addNotification({
        title: "Error",
        message: boot.registerPassError,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__zoomOut"],
        dismiss: {
          duration: 5000,
          onScreen: false,
          showIcon: true
        }
      });
    }
  };
  const renderCountries = () => {
    const renderOptions = [];
    const countries = getCountries();
    const countriesCode = Object.keys(countries);
    const countriesName = Object.values(countries);
    countriesCode.forEach((key, index) => {
      renderOptions.push(
        <option className="register__country-options" key={key} value={key}>
          {countriesName[index]}
        </option>
      );
    });
    return renderOptions;
  };

  useEffect(() => {
    (async () => {
      const data = await fetch("https://api.ipregistry.co/?key=ccu1zlzhgetyu5");
      const response = await data.json();
      const phoneCode = await response.location.country.calling_code;
      const country = await response.location.country.code;
      setForm({
        ...form,
        phoneCode: `+${phoneCode}`,
        country
      });
    })();
  }, []);

  return (
    <>
      {loader && <Loader message={loader} />}
      <ReactNotification />
      <section className="register">
        <div className="register__container">
          <div className="register__lang">
            <Language />
          </div>
          <div className="register__head">
            <a
              className="register__logo-container"
              href="https://adrswap.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Logo} alt="Logo" className="register__logo" />
            </a>

            <h1 className="register__title">{boot.title}</h1>
          </div>
          <form className="register__form" onSubmit={registerUser}>
            <label htmlFor="firstName" className="register__item">
              <p className="register__text">{boot.name}</p>
              <input
                id="firstName"
                name="firstName"
                value={form.firstName}
                onChange={handleInput}
                onBlur={handleBlur}
                type="text"
                className={`register__input${
                  errorInput.includes("firstName")
                    ? " register__input--error"
                    : ""
                }`}
                required
              />
            </label>
            <label htmlFor="lastName" className="register__item">
              <p className="register__text">{boot.lastName}</p>
              <input
                id="lastName"
                name="lastName"
                value={form.lastName}
                onChange={handleInput}
                onBlur={handleBlur}
                type="text"
                className={`register__input${
                  errorInput.includes("lastName")
                    ? " register__input--error"
                    : ""
                }`}
                required
              />
            </label>
            <label htmlFor="email" className="register__item">
              <p className="register__text">{boot.email}</p>
              <input
                id="email"
                type="email"
                name="email"
                value={form.email}
                onChange={handleInput}
                onBlur={handleBlur}
                className={`register__input${
                  errorInput.includes("email") ? " register__input--error" : ""
                }`}
                required
              />
            </label>
            <label
              htmlFor="phoneNumber"
              className="register__item register__item"
            >
              <p className="register__text">{boot.phone}</p>
              <span className="register__phone">
                <input
                  id="phoneCode"
                  type="text"
                  placeholder={form.phoneCode}
                  disabled
                  className="register__input register__input--code"
                  maxLength="5"
                />
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  value={form.phoneNumber}
                  onChange={handleInput}
                  onBlur={handleBlur}
                  type="tel"
                  className={`register__input${
                    errorInput.includes("phoneNumber")
                      ? " register__input--error"
                      : ""
                  }`}
                  required
                />
              </span>
            </label>
            <label htmlFor="country" className="register__item">
              <p className="register__text">{boot.country}</p>
              <select
                name="country"
                id="country"
                value={form.country}
                onChange={handleInput}
                onBlur={handleBlur}
                className={`register__input${
                  errorInput.includes("country")
                    ? " register__input--error"
                    : ""
                }`}
                required
              >
                {renderCountries()}
              </select>
            </label>
            <label
              htmlFor="password"
              className="register__item register__item--password"
            >
              <p className="register__text">{boot.password}</p>
              <input
                id="password"
                type={showPass ? "text" : "password"}
                name="password"
                value={form.password}
                onChange={handleInput}
                onBlur={handleBlur}
                className={`register__input${
                  errorInput.includes("password")
                    ? " register__input--error"
                    : ""
                }`}
                required
                title="La contraseña debe tener al menos una letra, un número y una longitud mayor a 6"
                minLength="6"
              />
              <span className="register__legend">{boot.legend}</span>
              <button
                type="button"
                onClick={() => setShowPass(!showPass)}
                className="register__show-password"
              >
                <img src={EyeIcon} className="register__eye" alt="Password" />
              </button>
            </label>
            <article className="register__item register__item--double">
              <button
                id="submitButton"
                type="submit"
                className="register__submit"
              >
                {boot.submit}
              </button>
            </article>
          </form>
        </div>
      </section>
    </>
  );
}
