import React, { useEffect, useState } from "react";
import { useAppContext } from "@context";
import getBaseApi from "@routes";

import "@stylesPages/Home.scss";

import ActionPlatformIcon from "@icons/action_platform_icon.svg";
import ActionDepositIcon from "@icons/action_deposit_icon.svg";

import Hero from "@components/Hero";
import Table from "@components/Table";

export default function Home({ history, boot }) {
  // eslint-disable-next-line no-unused-vars
  const [{ user, sessionId }, dispatch] = useAppContext();
  const [tabActive, setTabActive] = useState({ label: boot.summary.title, id: 0 });
  const [dataTrans, setDataTrans] = useState({ open: [], close: [] });
  const [dataActivity, setDataActivity] = useState([]);
  const [sumary, setSumary] = useState({
    totalDeposits: 0,
    total_credits: 0,
    totalWithdrawal: 0,
    total_positions: 0
  });
  const [dataSumaryActives, setDataSumaryActives] = useState([]);
  const [dataSumaryDemo, setDataSumaryDemo] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const handleChangeTab = (tab) => {
    setTabActive(tab);
  };

  const handleHistory = (to) => {
    history.push(to);
  };
  const cifras = (cifra) => {
    if (cifra % 1 === 0) {
      return `$${new Intl.NumberFormat("en-IN").format(cifra)}.00`;
    }
    return `$${new Intl.NumberFormat("en-IN").format(cifra)}`;
  };
  const columns2 = [
    {
      label: boot.summary.table2Column1,
      name: "login"
    },
    {
      label: boot.summary.table2Column2,
      name: "currency"
    },
    {
      label: boot.summary.table2Column3,
      name: "margin"
    },
    {
      label: boot.summary.table2Column4,
      name: "margin_free",
      sortable: true
    },
    {
      label: boot.summary.table2Column5,
      name: "equity",
      sortable: true
    },
    {
      label: boot.summary.table2Column6,
      name: "enable"
    },
    {
      label: boot.summary.table2Column7,
      name: "platform",
      sortable: true
    },
    {
      label: boot.summary.table2Column8,
      name: "actions"
    }
  ];
  const columns = [
    {
      label: boot.summary.table2Column1,
      name: "login"
    },
    {
      label: boot.summary.table2Column2,
      name: "currency"
    },
    {
      label: boot.summary.table2Column3,
      name: "margin"
    },
    {
      label: boot.summary.table2Column4,
      name: "margin_free",
      sortable: true
    },
    {
      label: boot.summary.table2Column5,
      name: "equity",
      sortable: true
    },
    {
      label: boot.summary.table2Column6,
      name: "enable"
    },
    {
      label: boot.summary.table2Column7,
      name: "platform",
      sortable: true
    },
    {
      label: boot.summary.table2Column8,
      name: "actions",
      cell: () => (
        <div className="actions__buttons">
          <span className="actions__item">
            <button onClick={() => handleHistory("/platform")} type="button" className="actions__button">
              <img src={ActionPlatformIcon} alt="" className="actions__icon" />
            </button>
            <p className="actions__text">{boot.activity.label7}</p>
          </span>
          <span className="actions__item">
            <button onClick={() => handleHistory("/funds")} type="button" className="actions__button">
              <img src={ActionDepositIcon} alt="" className="actions__icon" />
            </button>
            <p className="actions__text">{boot.activity.label8}</p>
          </span>
        </div>
      )
    }
  ];

  const colTransacciones = [
    {
      label: boot.transactions.tableColumn1,
      name: "Id"
    },
    {
      label: boot.transactions.tableColumn3,
      name: "coin"
    },
    {
      label: boot.transactions.tableColumn4,
      name: "Amount"
    },
    {
      label: boot.transactions.tableColumn5,
      name: "Type"
    },
    {
      label: boot.transactions.tableColumn6,
      name: "status"
    }
  ];
  const colActivities = [
    {
      label: boot.activity.table2Column1,
      name: "is_open"
    },
    {
      label: boot.activity.table2Column2,
      name: "open_time"
    },
    {
      label: boot.activity.table2Column3,
      name: "symbol"
    },
    {
      label: boot.activity.table2Column4,
      name: "open_price"
    },
    {
      label: boot.activity.table2Column5,
      name: "sl"
    },
    {
      label: boot.activity.table2Column6,
      name: "tp"
    },
    {
      label: boot.activity.table2Column7,
      name: "volume"
    },
    {
      label: boot.activity.table2Column8,
      name: "swaps"
    },
    {
      label: boot.activity.table2Column9,
      name: "profit"
    }
  ];
  const selectPeriod = [
    { id: 1, period: "ALL" },
    { id: 2, period: "Today" },
    { id: 3, period: "Yesterday" },
    { id: 4, period: "This Month" },
    { id: 5, period: "This Year" },
    { id: 6, period: "Range" }
  ];
  const getOperation = async (status = "ALL", dateType = "ALL", dateStart, dateEnd) => {
    let url = "";
    if (dateType === "Range") {
      url = `${getBaseApi()}/get/CustomerData?status=${status}&dateType=${dateType}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
    } else {
      url = `${getBaseApi()}/get/CustomerData?status=${status}&dateType=${dateType}`;
    }
    const repsonse = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionId}`
      }
    });
    const { data, error, result } = await repsonse.json();
    if (result) {
      if (status === "ALL") {
        const all = data.tp_accounts.positions.map((item, index) => (
          {
            id: index + 1,
            is_open: item.is_open,
            open_time: item.open_time,
            profit: cifras(item.profit),
            open_price: cifras(item.open_price),
            sl: cifras(item.sl),
            tp: cifras(item.tp),
            volume: item.volume,
            symbol: item.symbol,
            swaps: item.swaps
          }
        ));
        setDataActivity(all);
      } else if (status === "OPEN") {
        const open = data.tp_accounts.open.map((item, index) => (
          {
            id: index + 1,
            is_open: item.is_open,
            open_time: item.open_time,
            profit: cifras(item.profit),
            open_price: cifras(item.open_price),
            sl: cifras(item.sl),
            tp: cifras(item.tp),
            volume: item.volume,
            symbol: item.symbol,
            swaps: item.swaps
          }
        ));
        setDataActivity(open);
      } else if (status === "CLOSED") {
        const closed = data.tp_accounts.closed.map((item, index) => (
          {
            id: index + 1,
            is_open: item.is_open,
            open_time: item.open_time,
            profit: cifras(item.profit),
            open_price: cifras(item.open_price),
            sl: cifras(item.sl),
            tp: cifras(item.tp),
            volume: item.volume,
            symbol: item.symbol,
            swaps: item.swaps
          }
        ));
        setDataActivity(closed);
      }
    }
  };
  const handleCheck = (e) => {
    if (e.target.id === "openOperations") {
      document.getElementById("closeOperations").checked = false;
    } else if (e.target.id === "closeOperations") {
      document.getElementById("openOperations").checked = false;
    }
  };
  const handleFilters = (e) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const dateType = (form.get("operations") !== null) ? form.get("operations") : "ALL";
    if (form.get("period") === "Range") {
      getOperation(dateType, form.get("period"), form.get("from"), form.get("to"));
    } else {
      getOperation(dateType, form.get("period"));
    }
  };
  const setRange = (period) => {
    const monthsDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const fromRange = document.getElementById("from");
    const toRange = document.getElementById("to");
    const date = new Date();
    const yearNow = date.getFullYear();
    const monthNow = (date.getMonth() + 1 < 10) ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const dayNow = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
    if (period === "ALL") {
      fromRange.value = "0000-00-00";
      toRange.value = "0000-00-00";
    }
    if (period === "Today") {
      fromRange.value = `${yearNow}-${monthNow}-${dayNow}`;
      toRange.value = `${yearNow}-${monthNow}-${dayNow}`;
    } else if (period === "Yesterday") {
      if (date.getDate() === 1) {
        const month = (date.getMonth() < 10) ? `0${date.getMonth()}` : date.getMonth();
        fromRange.value = `${yearNow}-${month}-${monthsDays[date.getMonth()]}`;
        toRange.value = `${yearNow}-${month}-${monthsDays[date.getMonth()]}`;
      } else {
        fromRange.value = `${yearNow}-${monthNow}-${dayNow - 1}`;
        toRange.value = `${yearNow}-${monthNow}-${dayNow - 1}`;
      }
    } else if (period === "This Month") {
      fromRange.value = `${yearNow}-${monthNow}-01`;
      toRange.value = `${yearNow}-${monthNow}-${dayNow}`;
    } else if (period === "This Year") {
      fromRange.value = `${yearNow}-01-01`;
      toRange.value = `${yearNow}-${monthNow}-${dayNow}`;
    } else if (period === "Range") {
      fromRange.value = "0000-00-00";
      toRange.value = "0000-00-00";
    }
  };
  const handlePeriod = (e) => {
    if (e.target.value === "Range") {
      document.getElementById("from").disabled = false;
      document.getElementById("to").disabled = false;
    } else {
      document.getElementById("from").disabled = true;
      document.getElementById("to").disabled = true;
    }
    setRange(e.target.value);
  };
  console.log(dataSumaryActives);
  useEffect(() => {
    const getSumary = async () => {
      const repsonse = await fetch(`${getBaseApi()}/get/CustomerData`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionId}`
        }
      });
      const { data, error, result } = await repsonse.json();
      if (result) {
        setSumary({
          totalDeposits: data.summary.totalDeposits,
          total_credits: data.summary.total_credits,
          totalWithdrawal: data.summary.totalWithdrawal,
          total_positions: data.summary.total_positions
        });
        setDataSumaryActives([
          ...dataSumaryActives,
          { id: 1, ...data.tp_accounts.data }
        ]);
        const selectAccounts = { id: 1, account: data.tp_accounts.data.login };
        setAccounts(selectAccounts);
        setDataSumaryDemo([
          ...dataSumaryActives,
          { id: 1, ...data.tp_accounts_d.data }
        ]);
        getOperation("ALL");
      }
    };
    const getTrans = () => {
      fetch(`${getBaseApi()}/manage/Transaction`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionId}`
        }
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.result) {
            const dataClosed = response.data.closed.map((item, key) => (
              {
                id: key + 1,
                Id: item.idTransaction,
                account: item.tpId,
                coin: item.currency,
                Amount: item.amount,
                Type: item.type,
                status: item.status
              }
            ));
            const dataOpen = response.data.open.map((item, key) => (
              {
                id: key + 1,
                Id: item.idTransaction,
                account: item.tpId,
                coin: item.currency,
                Amount: item.amount,
                Type: item.type,
                status: item.status
              }
            ));
            setDataTrans({
              open: dataOpen,
              close: dataClosed
            });
          }
        });
    };
    getTrans();
    getSumary();
  }, []);
  return (
    <section className="home">
      <Hero
        title={`${boot.title}, ${user.firstName}`}
        tabs={[
          {
            label: boot.summary.title,
            id: 0
          },
          {
            label: boot.activity.title,
            id: 1
          }, {
            label: boot.transactions.title,
            id: 2
          }
        ]}
        activeTab={tabActive}
        changeTab={handleChangeTab}
      />
      {
        tabActive.id === 0 && (
          <div className="home__tab home__tab--nopadding">
            <div className="home__summary-amount">
              <h2 className="home__tab-title">{boot.summary.title}</h2>
              <div className="home__summary-table">
                <div className="home__summary-table-item">
                  <p className="home__summary-table-title">{boot.summary.tableColumn1}</p>
                  <p className="home__summary-table-price">{`$${sumary.totalDeposits}`}</p>
                </div>
                <div className="home__summary-table-item">
                  <p className="home__summary-table-title">{boot.summary.tableColumn2}</p>
                  <p className="home__summary-table-price">{`$${sumary.total_credits}`}</p>
                </div>
                <div className="home__summary-table-item">
                  <p className="home__summary-table-title">{boot.summary.tableColumn3}</p>
                  <p className="home__summary-table-price">{`$${sumary.totalWithdrawal}`}</p>
                </div>
                <div className="home__summary-table-item">
                  <p className="home__summary-table-title">{boot.summary.tableColumn4}</p>
                  <p className="home__summary-table-price">{sumary.total_positions}</p>
                </div>
              </div>
              <button
                type="button"
                className="home__summary-insert"
                onClick={() => handleHistory("/funds")}
              >
                {boot.summary.submit}
              </button>
            </div>
            <div className="home__summary-content">
              <h3 className="home__subtitle">{boot.summary.table1Title}</h3>
              <Table columns={columns} data={dataSumaryActives} />
              <br />
              <br />
              <br />
              <br />
              {/* <h3 className="home__subtitle">{boot.summary.table2Title}</h3> */}
              {/* <Table columns={columns2} data={dataSumaryDemo} /> */}
            </div>
          </div>
        )
      }
      {
        tabActive.id === 1 && (
          <div className="home__tab">
            <h2 className="home__tab-title">{boot.activity.title}</h2>
            {/* <label data-text={boot.activity.label1} htmlFor="accountNumber" className="home__activity-label">
              <select name="accountNumber" id="accountNumber" className="home__activity-input">
                {
                  accounts.map((item) => (
                    <option value={item.account} key={item.id}>{item.account}</option>
                  ))
                }
              </select>
            </label> */}
            <Table columns={columns} data={dataSumaryActives} />
            <h3 className="home__subtitle">{boot.activity.table2Title}</h3>
            <form className="home__activity-form" onSubmit={handleFilters}>
              <label data-text={boot.activity.label2} htmlFor="period" className="home__activity-form-label">
                <select name="period" id="period" className="home__activity-form-input" onChange={handlePeriod}>
                  {
                    selectPeriod.map((item) => (
                      <option value={item.period} key={item.id}>{item.period}</option>
                    ))
                  }
                </select>
              </label>
              <label data-text={boot.activity.label3} htmlFor="from" className="home__activity-form-label">
                <input type="date" name="from" id="from" className="home__activity-form-input" disabled />
              </label>
              <label data-text={boot.activity.label4} htmlFor="to" className="home__activity-form-label">
                <input type="date" name="to" id="to" className="home__activity-form-input" disabled />
              </label>
              <label htmlFor="openOperations" className="home__activity-form-label home__activity-form-label--checkbox">
                <input
                  type="checkbox"
                  name="operations"
                  id="openOperations"
                  value="OPEN"
                  onChange={handleCheck}
                  className="home__activity-form-input home__activity-form-input--checkbox"
                />
                <p className="home__activity-form-text">{boot.activity.label5}</p>
              </label>
              <label htmlFor="closeOperations" className="home__activity-form-label home__activity-form-label--checkbox">
                <input
                  type="checkbox"
                  name="operations"
                  value="CLOSED"
                  onChange={handleCheck}
                  id="closeOperations"
                  className="home__activity-form-input home__activity-form-input--checkbox"
                />
                <p className="home__activity-form-text">{boot.activity.label6}</p>
              </label>
              <button type="submit" className="home__activity-form-submit">{boot.activity.submit}</button>
            </form>
            <Table columns={colActivities} data={dataActivity} />
          </div>
        )
      }
      {
        tabActive.id === 2 && (
          <div className="home__tab">
            <h2 className="home__tab-title">{boot.transactions.title}</h2>
            <h3 className="home__subtitle">{boot.transactions.table1Title}</h3>
            <Table columns={colTransacciones} data={dataTrans.open} />
            <h3 className="home__subtitle">{boot.transactions.table2Title}</h3>
            <Table columns={colTransacciones} data={dataTrans.close} />
          </div>
        )
      }
    </section>
  );
}
