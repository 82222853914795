import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useAppContext } from "@context";

import Layout from "@components/Layout";
import Home from "@pages/Home";
import Support from "@pages/Support";
import Platform from "@pages/Platform";
import Profile from "@pages/Profile";
import Funds from "@pages/Funds";
import Login from "@pages/Login";
import Register from "@pages/Register";
import Forgot from "@pages/Forgot";

export default function App() {
  const [{ boot, sessionId }] = useAppContext();
  const ToLogin = () => <Redirect to="/login" />;
  const ToHome = () => <Redirect to="/account" />;

  return (
    <BrowserRouter>
      <Layout boot={boot}>
        <Switch>
          <Route exact path="/account" render={(props) => (sessionId ? <Home boot={boot.home} {...props} /> : <ToLogin />)} />
          <Route exact path="/funds" render={(props) => (sessionId ? <Funds boot={boot.funds} {...props} /> : <ToLogin />)} />
          <Route exact path="/support" render={(props) => (sessionId ? <Support boot={boot.support} {...props} /> : <ToLogin />)} />
          <Route exact path="/platform" render={(props) => (sessionId ? <Platform boot={boot.platform} {...props} /> : <ToLogin />)} />
          <Route exact path="/profile" render={(props) => (sessionId ? <Profile boot={boot.profile} {...props} /> : <ToLogin />)} />
          <Route exact path="/login" render={(props) => (sessionId ? <ToHome /> : <Login boot={boot.login} {...props} />)} />
          <Route exact path="/register" render={(props) => (sessionId ? <ToHome /> : <Register boot={boot.register} {...props} />)} />
          <Route exact path="/forgot" render={(props) => (sessionId ? <ToHome /> : <Forgot boot={boot.forgot} {...props} />)} />
          <Route component={sessionId ? ToHome : ToLogin} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}
