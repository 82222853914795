/* eslint-disable max-len */

export default function getBoot() {
  return {
    en: {
      login: {
        title: "Welcome to Adrswap",
        password: "Password:",
        forgot: "Forgot Your Password?",
        submit: "Login",
        notAccount: "Don't have an account?",
        register: "Register"
      },
      register: {
        title: "Registration",
        name: "Name:",
        lastName: "Last name:",
        email: "Email:",
        phone: "Telephone:",
        country: "Country:",
        password: "Password:",
        legend:
          "*The password must be at least one letter, number, and length greater than 6*",
        submit: "Create account",
        registering: "Creating the account",
        registerSuccess: "Registered user successfully!",
        registerError: "The email you are trying to use is already registered.",
        registerPassError: "Invalid password format"
      },
      forgot: {
        submit: "Recover password"
      },
      home: {
        title: "Welcome",
        summary: {
          title: "Summary",
          submit: "Insert funds",
          tableColumn1: "Total deposits",
          tableColumn2: "Credits granted",
          tableColumn3: "Total withdrawals",
          tableColumn4: "Total trades",
          table1Title: "Active accounts",
          table2Title: "Demo accounts",
          table2Column1: "Account",
          table2Column2: "Currency",
          table2Column3: "Margin",
          table2Column4: "Free margin",
          table2Column5: "Total balance",
          table2Column6: "Status",
          table2Column7: "Platform",
          table2Column8: "Actions"
        },
        activity: {
          title: "Activity",
          label1: "Account number",
          label2: "Period",
          label3: "From",
          label4: "To",
          label5: "Open trades",
          label6: "Close trades",
          label7: "Platform",
          label8: "Deposit",
          submit: "Apply filter",
          table1Column1: "Account",
          table1Column2: "Currency",
          table1Column3: "Margin",
          table1Column4: "Free margin",
          table1Column5: "Total balance",
          table1Column6: "Status",
          table1Column7: "Platform",
          table1Column8: "Actions",
          table2Title: "Operations",
          table2Column1: "Account",
          table2Column2: "Date",
          table2Column3: "Symbol",
          table2Column4: "Aper price",
          table2Column5: "S/L",
          table2Column6: "T/P",
          table2Column7: "Price",
          table2Column8: "Swap",
          table2Column9: "P/L"
        },
        transactions: {
          title: "Transactions",
          table1Title: "Open transactions",
          table2Title: "Close transactions",
          tableColumn1: "Tickets",
          tableColumn2: "Account",
          tableColumn3: "Currency",
          tableColumn4: "Amount",
          tableColumn5: "Type",
          tableColumn6: "Status"
        }
      },
      funds: {
        title: "Funds",
        sectionTitle: "Choose account",
        label: "Account number",
        deposit: {
          title: "Deposit",
          section1Title: "My cards",
          section2Title: "Add card",
          section2TitleEdit: "Edit card",
          label1: "Name of holder",
          label2: "Card number",
          label3: "MM/YY",
          label4: "CCV",
          label5: "Amount:",
          label6: "Select the bank:",
          amountTitle: "Amount to deposit:",
          legend: "By clicking, you accept these ",
          conditions: "Terms of Use",
          cancel: "Cancel",
          save: "Save",
          add: "Add new card",
          errorFormat: "Invalid format"
        },
        alert: {
          card: {
            confirmDeleteTitle: "Are you sure to delete this card?",
            confirmDeleteBody: "This card is going to be deleted",
            cancelDelete: "The card haven't been deleted",
            delete: "The card have been deleted successfully",
            addedCard: "The card have been added successfully",
            errorAdd: "Error the card already exist",
            updatedCard: "The card have been updated successfully",
            errorFormat: "Invalid data"
          },
          withdrawal: {
            success: "successful operation",
            errorAmount: "Error: the minimum amount should be 250 USD",
            errorTime: "Error: you have wait one hour to do another operation",
            errorFunds: "You don't have enough funds"
          }
        },
        extract: {
          title: "Extract",
          sectionTitle: "Reasons",
          label1: "Currency",
          label2: "Quantity",
          label3: "Why you draw funds",
          submit: "Send"
        }
      },
      platform: {
        title: "Platform",
        section1Title: "Sirix web platform",
        section2Title: "Download the platform for pc",
        section3Title: "Download anydesk",
        label: "Account number",
        download1: "Metatrader 4 for PC",
        download2: "AnyDesk for PC",
        download3: "AnyDesk for Android",
        download4: "AnyDesk for IOS",
        download5: "AnyDesk for MacOs",
        button1: "Go to the platform",
        button2: "Download",
        alertErrorSirix: "Your session has expired, please login again",
        alertErrorSirixGeneral: "Plase try later"
      },
      profile: {
        title: "My Profile",
        cancel: "Cancel",
        save: "Save",
        data: {
          title: "My data",
          form1Title: "General information",
          form2Title: "Change password",
          label1: "Name",
          label2: "Last name",
          label3: "Email",
          label4: "Country",
          label5: "State",
          label6: "City",
          label7: "C.P.",
          label8: "Address",
          label9: "Telephone",
          label10: "Current password",
          label11: "New password",
          label12: "Confirm password",
          legend: `You can change your current password here.
                  We recommend that you use a password that
                  cannot be easily decrypted. We recommend
                  that you combine letters with numbers.
                  Your personal account will be highly
                  protected with 128-bit SSL encryption
                  that guarantees the security of your
                  personal data and transactions`,
          edit: "Edit photo",
          modify1: "Maximum size allowed: 5mb |",
          modify2: "Allowed extensions: JPG, PNG"
        },
        alert: {
          imageUpdateTitle: "Image updated",
          imageUpdateBody: "The image have been updated successfully",
          imageError: "Error the imagen haven't been updated",
          passSuccessTitle: "Password Updated",
          passSuccessBody: "The password have been updated",
          passError: "Error, The password haven't been updated",
          passValida: "Incorrect password format",
          dataUploadBody: "Data have been updated successfully",
          dataError: "Data haven't been updated",
          documentErrorUploadFileTitle: "Error",
          documentErrorUploadFileBody: "Documents haven't saved",
          documentErrorID: "Identity documents incomplete",
          documentErrorType: "Documents should be in PDF",
          documentUpdateSuccess: "Documents have been saved successfully",
          documentUpdateError: "Error, Documents haven't been saved",
          documentsEmpty: "Empty documents"
        },
        documents: {
          title: "My documents",
          description: `Activate your account and get access to all available 
                        features by uploading your Identity Document (ID) and 
                        Proof of Residence (POR) using the following file upload forms.`,
          button: "Search document",
          titleForm1: "Identity documents",
          titleForm2: "Proof of residence",
          titleForm3: "Verification form",
          titleForm4: "Credit cards",
          titleForm5: "Proof of payment",
          label1: "Front",
          label2: "Back",
          label3: "Residence",
          label4: "Form",
          label5: "Card",
          label6: "Voucher",
          legend: "All submitted files go through a validation process",
          save: "Save and send"
        }
      },
      support: {
        title: "Support",
        title2: "Retreats",
        table1Title: "Open tickets",
        table2Title: "Closed tickets",
        tableColumn1: "Tickets",
        tableColumn2: "Account",
        tableColumn3: "Amount",
        tableColumn4: "Currency",
        tableColumn5: "State"
      },
      header: {
        link1: "Trading account",
        link2: "Funds",
        link3: "Platform",
        link4: "Support",
        link5: "My profile",
        hello: "Hello",
        session: "Logout"
      },
      footer: {
        title1: "Accounts",
        title2: "Legal",
        title3: "About",
        title4: "Platform",
        title5: "Education",
        title6: "Market",
        title7: "Support",
        copyright:
          "©Copyright 2021 Adrswap - All rights reserved. \n \nHIGH RISK INVESTMENT WARNING: Trading for leverage in foreign currency contracts, contracts for difference or other off-exchange products carries a high level of risk and may not be suitable for everyone. Before carrying out operations, it is strongly recommended to read and make sure you understand the relevant information and risk warnings that we present: Risk Disclosure Statement. There is a significant risk of losing your entire initial investment. We advise you to consider whether trading products with leverage is appropriate in light of your own personal circumstances. We recommend that you seek independent financial advice and make sure you fully understand all the risks involved before trading. Trading through an online platform carries additional risks. Restricted Jurisdictions: We do not establish accounts with residents of certain jurisdictions including the European Union, the United States, or any particular country or jurisdiction where such distribution or use is contrary to local law or regulations. For more details, see the terms and conditions.",
        text1: "Type of accounts",
        text2: "Terms and Conditions",
        text3: "Contact us",
        text4: "Sirix Web Trader"
      },
      card: {
        delete: "Delete card",
        edit: "Edit card"
      }
    },
    es: {
      login: {
        title: "Bienvenido a Adrswap",
        password: "Contraseña:",
        forgot: "¿Olvidaste Tu Contraseña?",
        submit: "Iniciar sesión",
        notAccount: "¿No tienes una cuenta?",
        register: "Regístrate"
      },
      register: {
        title: "Registro",
        name: "Nombre:",
        lastName: "Apellido:",
        email: "Correo:",
        phone: "Teléfono:",
        country: "País:",
        password: "Contraseña:",
        legend:
          "*La contraseña debe tener al menos una letra, un número y una longitud mayor a 6*",
        submit: "Crear cuenta",
        registering: "Creando la cuenta",
        registerSuccess: "¡Usuario registrado con éxito!",
        registerError: "El correo que intentas usar ya esta registrado.",
        registerPassError: "Formato de contraseña incorrecta"
      },
      forgot: {
        submit: "Recuperar contraseña"
      },
      home: {
        title: "Bienvenido(a)",
        summary: {
          title: "Resumen",
          submit: "Insertar fondos",
          tableColumn1: "Depósitos totales",
          tableColumn2: "Créditos otorgados",
          tableColumn3: "Total de retiros",
          tableColumn4: "Operaciones totales",
          table1Title: "Cuentas activas",
          table2Title: "Cuentas demo",
          table2Column1: "Cuenta",
          table2Column2: "Moneda",
          table2Column3: "Margen",
          table2Column4: "Margen libre",
          table2Column5: "Balance total",
          table2Column6: "Estado",
          table2Column7: "Plataforma",
          table2Column8: "Acciones"
        },
        activity: {
          title: "Actividad",
          label1: "Número de cuenta",
          label2: "Periodo",
          label3: "De",
          label4: "A",
          label5: "Operaciones abiertas",
          label6: "Operaciones cerradas",
          label7: "Plataforma",
          label8: "Depositar",
          submit: "Aplicar filtro",
          table1Column1: "Cuenta",
          table1Column2: "Moneda",
          table1Column3: "Margen",
          table1Column4: "Margen libre",
          table1Column5: "Balance total",
          table1Column6: "Estado",
          table1Column7: "Plataforma",
          table1Column8: "Acciones",
          table2Title: "Operaciones",
          table2Column1: "Cuenta",
          table2Column2: "Fecha",
          table2Column3: "Simbolo",
          table2Column4: "Precio Aper",
          table2Column5: "S/L",
          table2Column6: "T/P",
          table2Column7: "Precio",
          table2Column8: "Swap",
          table2Column9: "P/L"
        },
        transactions: {
          title: "Transacciones",
          table1Title: "Transacciones abiertas",
          table2Title: "Transacciones cerradas",
          tableColumn1: "Tickets",
          tableColumn2: "Cuenta",
          tableColumn3: "Moneda",
          tableColumn4: "Cantidad",
          tableColumn5: "Tipo",
          tableColumn6: "Estado"
        }
      },
      funds: {
        title: "Fondos",
        sectionTitle: "Elegir cuenta",
        label: "Número de cuenta",
        deposit: {
          title: "Depositar",
          section1Title: "Mis tarjetas",
          section2Title: "Agregar tarjeta",
          section2TitleEdit: "Editar tarjeta",
          label1: "Nombre del titular",
          label2: "Número de tarjeta",
          label3: "MM/AA",
          label4: "CCV",
          label5: "Monto:",
          label6: "Selecciona el banco:",
          amountTitle: "Monto a depositar:",
          legend: "Al dar click, aceptas estas ",
          conditions: "Condiciones de uso",
          cancel: "Cancelar",
          save: "Guardar",
          add: "Agregar nueva tarjeta",
          errorFormat: "Formato incorrecto"
        },
        alert: {
          card: {
            confirmDeleteTitle: "¿Éstas seguro de eliminar esta tarjeta?",
            confirmDeleteBody: "esta tarjeta está por ser eliminada",
            cancelDelete: "La tarjeta no fue eliminada",
            delete: "La tarjeta ha sido eliminada con éxito",
            addedCard: "La tarjeta ha sido agregada con éxito",
            errorAdd: "Error la tarjeta ya existe",
            updatedCard: "La tarjeta ha sido actualizada con éxito",
            errorFormat: "Datos invalidos"
          },
          withdrawal: {
            success: "Operación exitosa",
            errorAmount: "Error: La cantidad mínima debe ser 250",
            errorTime:
              "Error: Debes esperar una hora para poder realizar otro retiro",
            errorFunds: "No cuentas con los fondos suficientes"
          }
        },
        extract: {
          title: "Extraer",
          sectionTitle: "Motivos",
          label1: "Moneda",
          label2: "Cantidad",
          label3: "Motivo por los que extrae fondos",
          submit: "Enviar"
        }
      },
      platform: {
        title: "Plataforma",
        section1Title: "Sirix plataforma web",
        section2Title: "Descargar la plataforma para pc",
        section3Title: "Descargar anydesk",
        label: "Número de cuenta",
        download1: "Metatrader 4 para PC",
        download2: "AnyDesk para PC",
        download3: "AnyDesk para Android",
        download4: "AnyDesk para IOS",
        download5: "AnyDesk para MacOs",
        button1: "Ir a la plataforma",
        button2: "Descargar",
        alertErrorSirix: "Tu sessión ha expirado, favor de volver a ingresar",
        alertErrorSirixGeneral: "Ha ocurrido un error, intente más tarde"
      },
      profile: {
        title: "Mi Perfil",
        cancel: "Cancelar",
        save: "Guardar",
        data: {
          title: "Mis datos",
          form1Title: "Información general",
          form2Title: "Cambiar contraseña",
          label1: "Nombre",
          label2: "Apellido",
          label3: "Correo",
          label4: "País",
          label5: "Estado",
          label6: "Ciudad",
          label7: "C.P.",
          label8: "Dirección",
          label9: "Teléfono",
          label10: "Contraseña actual",
          label11: "Nueva contraseña",
          label12: "Confirmar contraseña",
          legend: `Puede cambiar su contraseña actual aquí. Le recomendamos
                  que utilice una contraseña que no se pueda descifrar fácilmente.
                  Le recomendamos que combine letras con números. Su cuenta
                  personal estará altamente protegida con encriptación SSL de 128 bits
                  que garantiza la seguridad de sus datos personales y transacciones.`,
          edit: "Editar foto",
          modify1: "Tamaño máximo permitido: 5mb |",
          modify2: "Extensiones permitidas: JPG, PNG"
        },
        alert: {
          imageUpdateTitle: "Imagen actualizada",
          imageUpdateBody: "La imagen se ha actualizado con exito",
          imageError: "Error al actualizar imagen",
          passSuccessTitle: "Contraseña actualizada",
          passSuccessBody: "La contraseña se ha actualizado con exito",
          passError:
            "La contraseña no ha podido ser actualizada, por favor revisa los datos",
          passValida: "Formato de contraseña incorrecto",
          dataUploadBody: "Los datos se han guardado con éxito",
          dataError: "Data haven't been updated",
          documentErrorUploadFileTitle:
            "No se pudieron guardar todos los archivos",
          documentErrorUploadFileBody: "no se pudieron subir",
          documentErrorID: "Archivos de documento de identidad incompletos",
          documentErrorType: "Los archivos deben estar en formato PDF",
          documentUpdateSuccess: "Los cambios se han guardado con exito",
          documentUpdateError: "Error, los datos no se pudieron actualizar",
          documentsEmpty: "Documentos vacios"
        },
        documents: {
          title: "Mis documentos",
          description: `Active su cuenta y obtenga acceso a todas las funciones disponibles cargando su
              Documento de identidad (ID) y Prueba de residencia (POR) utilizando los siguientes
              formularios de carga de archivos.`,
          button: "Buscar documento",
          titleForm1: "Documentos de identidad",
          titleForm2: "Comprobante de residencia",
          titleForm3: "Formulario de verificación",
          titleForm4: "Tarjetas de crédito",
          titleForm5: "Comprobante de pago",
          label1: "Frente",
          label2: "Vuelta",
          label3: "Residencia",
          label4: "Formulario",
          label5: "Tarjeta",
          label6: "Comprobante",
          legend:
            "Todos los archivos enviados pasan por un proceso de validación",
          save: "Guardar y enviar"
        }
      },
      support: {
        title: "Soporte",
        title2: "Retiros",
        table1Title: "Tickets abiertos",
        table2Title: "Tickets cerrados",
        tableColumn1: "Tickets",
        tableColumn2: "Cuenta",
        tableColumn3: "Monto",
        tableColumn4: "Moneda",
        tableColumn5: "Estado"
      },
      header: {
        link1: "Cuenta trading",
        link2: "Fondos",
        link3: "Plataforma",
        link4: "Soporte",
        link5: "Mi perfil",
        hello: "Hola",
        session: "Cerrar sesión"
      },
      footer: {
        title1: "Cuentas",
        title2: "Legales",
        title3: "Nosotros",
        title4: "Plataforma",
        title5: "Formación",
        title6: "Mercado",
        title7: "Soporte",
        copyright:
          "©Copyright 2021 Adrswap - Todos los derechos reservados. \n \nADVERTENCIA SOBRE INVERSIÓN DE ALTO RIESGO: El trading por apalancamiento en contratos de divisas extranjeras, contratos por diferencia u otros productos fuera de intercambio conllevan un alto nivel de riesgo y puede que no sean adecuados para todos. Antes de realizar operaciones se recomienda encarecidamente leer y asegurarse de entender la relevante información y advertencias de riesgo que presentamos: Declaración de Divulgación de Riesgos . Existe un riesgo importante de poder perder toda su inversión inicial. Te aconsejamos considerar si es apropiado el hacer trading con productos con apalancamiento a la luz de tus propias circunstancias personales. Te recomendamos buscar asesoramiento financiero independiente y asegurarte de entender completamente todos los riesgos involucrados antes de negociar. Negociar mediante una plataforma en línea conlleva riesgos adicionales. Jurisdicciones restringidas: Nosotros no establecemos cuentas a residentes de ciertas jurisdicciones incluidos la Unión Europea, Estados Unidos o cualquier país o jurisdicción en particular en la que dicha distribución o uso sea contraria a la ley o regulaciones locales. Para más detalles, consulte los términos y condiciones.",
        text1: "Tipo de cuentas",
        text2: "Términos y Condiciones",
        text3: "Contáctanos",
        text4: "Sirix Web Trader"
      },
      card: {
        delete: "Eliminar tarjeta",
        edit: "Editar tarjeta"
      }
    }
  };
}
export function getCountries() {
  return {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AG: "Antigua and Barbuda",
    AI: "Anguilla",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AR: "Argentina",
    AS: "American Samoa",
    AT: "Austria",
    AU: "Australia",
    AW: "Aruba",
    AX: "Aland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint Barthelemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BQ: "Bonaire, Saint Eustatius and Saba ",
    BR: "Brazil",
    BS: "Bahamas",
    BT: "Bhutan",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    BZ: "Belize",
    CA: "Canada",
    CC: "Cocos Islands",
    CD: "Democratic Republic of the Congo",
    CF: "Central African Republic",
    CG: "Republic of the Congo",
    CH: "Switzerland",
    CI: "Ivory Coast",
    CK: "Cook Islands",
    CL: "Chile",
    CM: "Cameroon",
    CN: "China",
    CO: "Colombia",
    CR: "Costa Rica",
    CU: "Cuba",
    CV: "Cape Verde",
    CW: "Curacao",
    CX: "Christmas Island",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DM: "Dominica",
    DO: "Dominican Republic",
    DZ: "Algeria",
    EC: "Ecuador",
    EE: "Estonia",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    FR: "France",
    GA: "Gabon",
    GB: "United Kingdom",
    GD: "Grenada",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibraltar",
    GL: "Greenland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GS: "South Georgia and the South Sandwich Islands",
    GT: "Guatemala",
    GU: "Guam",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HK: "Hong Kong",
    HM: "Heard Island and McDonald Islands",
    HN: "Honduras",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    ID: "Indonesia",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IN: "India",
    IO: "British Indian Ocean Territory",
    IQ: "Iraq",
    IR: "Iran",
    IS: "Iceland",
    IT: "Italy",
    JE: "Jersey",
    JM: "Jamaica",
    JO: "Jordan",
    JP: "Japan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KH: "Cambodia",
    KI: "Kiribati",
    KM: "Comoros",
    KN: "Saint Kitts and Nevis",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KY: "Cayman Islands",
    KZ: "Kazakhstan",
    LA: "Laos",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LI: "Liechtenstein",
    LK: "Sri Lanka",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova",
    ME: "Montenegro",
    MF: "Saint Martin",
    MG: "Madagascar",
    MH: "Marshall Islands",
    MK: "Macedonia",
    ML: "Mali",
    MM: "Myanmar",
    MN: "Mongolia",
    MO: "Macao",
    MP: "Northern Mariana Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MS: "Montserrat",
    MT: "Malta",
    MU: "Mauritius",
    MV: "Maldives",
    MW: "Malawi",
    MX: "Mexico",
    MY: "Malaysia",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    NZ: "New Zealand",
    OM: "Oman",
    PA: "Panama",
    PE: "Peru",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PH: "Philippines",
    PK: "Pakistan",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    PN: "Pitcairn",
    PR: "Puerto Rico",
    PS: "Palestinian Territory",
    PT: "Portugal",
    PW: "Palau",
    PY: "Paraguay",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russia",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SB: "Solomon Islands",
    SC: "Seychelles",
    SD: "Sudan",
    SE: "Sweden",
    SG: "Singapore",
    SH: "Saint Helena",
    SI: "Slovenia",
    SJ: "Svalbard and Jan Mayen",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SR: "Suriname",
    SS: "South Sudan",
    ST: "Sao Tome and Principe",
    SV: "El Salvador",
    SX: "Sint Maarten",
    SY: "Syria",
    SZ: "Swaziland",
    TC: "Turks and Caicos Islands",
    TD: "Chad",
    TF: "French Southern Territories",
    TG: "Togo",
    TH: "Thailand",
    TJ: "Tajikistan",
    TK: "Tokelau",
    TL: "East Timor",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TO: "Tonga",
    TR: "Turkey",
    TT: "Trinidad and Tobago",
    TV: "Tuvalu",
    TW: "Taiwan",
    TZ: "Tanzania",
    UA: "Ukraine",
    UG: "Uganda",
    UM: "United States Minor Outlying Islands",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VA: "Vatican",
    VC: "Saint Vincent and the Grenadines",
    VE: "Venezuela",
    VG: "British Virgin Islands",
    VI: "U.S. Virgin Islands",
    VN: "Vietnam",
    VU: "Vanuatu",
    WF: "Wallis and Futuna",
    WS: "Samoa",
    XK: "Kosovo",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe"
  };
}
