import React, { useEffect, useState } from "react";
import getBaseApi from "@routes";
import { useAppContext } from "@context";
import swal from "sweetalert";

import "@stylesPages/Profile.scss";

import DefaultPhoto from "@icons/default_photo_icon.svg";

import Hero from "@components/Hero";

export default function Profile({ boot }) {
  const [tabActive, setTabActive] = useState({ label: boot.data.title, id: 0 });
  const [modalImage, setModalImage] = useState(false);
  const [{ user, sessionId }, dispatch] = useAppContext();
  const initialUser = {
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    street: "",
    phone: "",
    profileImage: "",
    fileImageUpdate: ""
  };
  const [dataUser, setDataUser] = useState(initialUser);

  const handleChangeTab = (tab) => {
    setTabActive(tab);
  };

  const handleModalImage = () => {
    document.querySelector("#fileSelector").click();
  };

  const handleCancelImage = () => {
    setDataUser({
      ...dataUser,
      profileImage: user.profileImage,
      fileImageUpdate: ""
    });
    setModalImage(!modalImage);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setDataUser({
      ...dataUser,
      profileImage: URL.createObjectURL(file),
      fileImageUpdate: file
    });
    setModalImage(!modalImage);
  };

  const handleSaveImage = () => {
    const data = new FormData();
    data.append("file", dataUser.fileImageUpdate);
    fetch(`${getBaseApi()}/manage/Customer`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionId}`
      },
      body: data
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            title: boot.alert.imageUpdateTitle,
            text: boot.alert.imageUpdateBody
          });
          dispatch({
            type: "CHANGEIMAGE",
            payload: response.data.url
          });
        } else {
          swal({
            icon: "error",
            text: boot.alert.imageError
          });
        }
      });
    setModalImage(!modalImage);
  };

  const handlePassword = async (event) => {
    event.preventDefault();
    const bntSubmit = event.target[3];
    bntSubmit.disabled = true;
    bntSubmit.style.opacity = 0.2;
    if (
      event.target[1].value === event.target[2].value
      && event.target[2].value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/g)
    ) {
      const data = {
        action: "password",
        oldPassword: event.target[0].value,
        newPassword: event.target[1].value,
        confirmPassword: event.target[2].value
      };
      const response = await fetch(`${getBaseApi()}/manage/Customer`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionId}`
        },
        body: JSON.stringify(data)
      });
      const { result } = await response.json();
      if (result) {
        swal({
          icon: "success",
          title: boot.alert.passSuccessTitle,
          text: boot.alert.passSuccessBody
        });
      } else {
        swal({
          icon: "error",
          text: boot.alert.passError
        });
      }
      event.target.reset();
    } else {
      swal({
        icon: "error",
        text: boot.alert.passValida
      });
    }
    bntSubmit.disabled = false;
    bntSubmit.style.opacity = 1;
  };
  const postFile = async (dataFile) => {
    const data = new FormData();
    data.append("file", dataFile.file);
    data.append("fileType", dataFile.type);
    data.append("extraType", dataFile.extratype);
    const response = await fetch(`${getBaseApi()}/manage/Documents`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionId}`
      },
      body: data
    });
    return response;
  };
  const handleDocuments = (event) => {
    event.preventDefault();
    const documents = [
      {
        name: event.target[1].name,
        type: event.target[1].dataset.filetype,
        extratype: event.target[1].dataset.extratype,
        file: event.target[1].files[0]
      },
      {
        name: event.target[4].name,
        type: event.target[4].dataset.filetype,
        extratype: event.target[4].dataset.extratype,
        file: event.target[4].files[0]
      },
      {
        name: event.target[7].name,
        type: event.target[7].dataset.filetype,
        file: event.target[7].files[0],
        extratype: event.target[7].dataset.extratype
      },
      {
        name: event.target[10].name,
        type: event.target[10].dataset.filetype,
        file: event.target[10].files[0],
        extratype: event.target[10].dataset.extratype
      },
      {
        name: event.target[13].name,
        type: event.target[13].dataset.filetype,
        file: event.target[13].files[0],
        extratype: event.target[13].dataset.extratype
      },
      {
        name: event.target[16].name,
        type: event.target[16].dataset.filetype,
        file: event.target[16].files[0],
        extratype: event.target[16].dataset.extratype
      }
    ];
    const filterDocuments = documents.filter((item) => item.file !== undefined);
    console.log(filterDocuments);
    if (filterDocuments.length !== 0) {
      const invalid = filterDocuments.filter(
        (item) => item.file.type !== "application/pdf"
      );
      if (!invalid.length > 0) {
        const sendFiles = () => {
          const errors = [];
          filterDocuments.forEach(async (item) => {
            const response = await postFile(item);
            const { result, error, data } = await response.json();
            if (!result) {
              errors.push(item);
            }
          });
          if (!errors.length > 0) {
            swal({
              icon: "success",
              text: boot.alert.documentUpdateSuccess
            });
          } else {
            swal({
              icon: "info",
              title: boot.alert.documentUpdateError,
              text: `${errors.length} ${boot.alert.documentErrorUploadFileBody}`
            });
          }
        };
        if (
          filterDocuments.filter((item) => item.name === "front").length > 0
          || filterDocuments.filter((item) => item.name === "lap").length > 0
        ) {
          if (
            filterDocuments.filter((item) => item.name === "lap").length > 0
            && filterDocuments.filter((item) => item.name === "front").length > 0
          ) {
            sendFiles();
          } else {
            swal({
              icon: "error",
              text: boot.alert.documentErrorID
            });
          }
        } else {
          sendFiles();
        }
      } else {
        swal({
          icon: "error",
          text: boot.alert.documentErrorType
        });
      }
    } else {
      swal({
        icon: "error",
        text: boot.alert.documentsEmpty
      });
    }
  };
  const handleDocument = (file) => {
    document.getElementById(`${file}`).click();
  };
  const handleChangeFile = (event) => {
    document.getElementById(`${event.target.name}`).value = event.target.files[0].name;
  };
  const handleEdit = (event) => {
    event.preventDefault();
    console.log({ target: event.target });
    const data = {
      action: "Information",
      firstName: document.getElementById("name").value,
      lastName: document.getElementById("lastName").value,
      state: document.getElementById("state").value,
      city: document.getElementById("city").value,
      zipCode: document.getElementById("postalCode").value,
      street: document.getElementById("direction").value
    };
    fetch(`${getBaseApi()}/manage/Customer`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionId}`
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result) {
          swal({
            icon: "success",
            text: boot.alert.dataUploadBody
          });
        } else {
          swal({
            icon: "error",
            text: boot.alert.dataError
          });
        }
      });
  };

  useEffect(() => {
    const getUser = () => {
      fetch(`${getBaseApi()}/manage/Customer?action=ById&tpId=${user.tpId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionId}`
        }
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.result) {
            setDataUser({
              ...dataUser,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              email: response.data.email,
              country: response.data.country,
              state: response.data.state,
              city: response.data.city,
              zipCode: response.data.zipCode,
              street: response.data.street,
              phone: response.data.phone,
              profileImage: response.data.profileImage
            });
          }
        });
    };
    getUser();
  }, []);
  return (
    <section className="profile">
      <Hero
        title={boot.title}
        tabs={[
          {
            label: boot.data.title,
            id: 0
          },
          {
            label: boot.documents.title,
            id: 1
          }
        ]}
        activeTab={tabActive}
        changeTab={handleChangeTab}
      />
      {tabActive.id === 0 ? (
        <div className="profile__data">
          <div className="profile__data-user">
            <h2 className="profile__data-title">{boot.data.form1Title}</h2>
            <form onSubmit={handleEdit} className="profile__data-form">
              {!modalImage ? (
                <div className="profile__data-image-container">
                  <img
                    src={
                      user.profileImage !== ""
                        ? user.profileImage
                        : DefaultPhoto
                    }
                    alt="User"
                    className="profile__data-image"
                  />
                  <button
                    onClick={handleModalImage}
                    type="button"
                    className="profile__data-image-edit"
                  >
                    {boot.data.edit}
                  </button>
                  <input
                    type="file"
                    id="fileSelector"
                    onChange={handleFileChange}
                    style={{ visibility: "hidden" }}
                  />
                </div>
              ) : (
                <div className="profile__data-image-container profile__data-image-container--modify">
                  <img
                    src={
                      dataUser.profileImage !== ""
                        ? dataUser.profileImage
                        : DefaultPhoto
                    }
                    alt="User"
                    className="profile__data-image profile__data-image--modify"
                  />
                  <span className="profile__data-image-text">
                    {boot.data.modify1}
                    <br />
                    {boot.data.modify2}
                  </span>
                  <div className="profile__data-image-buttons">
                    <button
                      onClick={handleCancelImage}
                      type="button"
                      className="profile__data-image-cancel"
                    >
                      {boot.cancel}
                    </button>
                    <button
                      onClick={handleSaveImage}
                      type="button"
                      className="profile__data-image-save"
                    >
                      {boot.save}
                    </button>
                  </div>
                </div>
              )}
              <div className="profile__data-form-grid">
                <label
                  data-text={boot.data.label1}
                  htmlFor="name"
                  className="profile__data-form-label"
                >
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="profile__data-form-input"
                    defaultValue={dataUser.firstName}
                    required
                  />
                </label>
                <label
                  data-text={boot.data.label2}
                  htmlFor="lastName"
                  className="profile__data-form-label"
                >
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="profile__data-form-input"
                    defaultValue={dataUser.lastName}
                    required
                  />
                </label>
                <label
                  data-text={boot.data.label3}
                  htmlFor="email"
                  className="profile__data-form-label"
                >
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="profile__data-form-input"
                    defaultValue={dataUser.email}
                    disabled
                  />
                </label>
                <label
                  data-text={boot.data.label4}
                  htmlFor="country"
                  className="profile__data-form-label"
                >
                  <input
                    type="text"
                    name="country"
                    id="country"
                    className="profile__data-form-input"
                    defaultValue={dataUser.country}
                    disabled
                  />
                </label>
                <label
                  data-text={boot.data.label5}
                  htmlFor="state"
                  className="profile__data-form-label"
                >
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className="profile__data-form-input"
                    defaultValue={dataUser.state}
                  />
                </label>
                <div className="profile__data-form-city">
                  <label
                    data-text={boot.data.label6}
                    htmlFor="city"
                    className="profile__data-form-label profile__data-form-label--city"
                  >
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="profile__data-form-input"
                      defaultValue={dataUser.city}
                    />
                  </label>
                  <label
                    data-text={boot.data.label7}
                    htmlFor="postalCode"
                    className="profile__data-form-label profile__data-form-label--code"
                  >
                    <input
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      className="profile__data-form-input"
                      defaultValue={dataUser.zipCode}
                    />
                  </label>
                </div>
                <label
                  data-text={boot.data.label8}
                  htmlFor="direction"
                  className="profile__data-form-label profile__data-form-label--direction"
                >
                  <input
                    type="text"
                    name="direction"
                    id="direction"
                    className="profile__data-form-input"
                    defaultValue={dataUser.street}
                  />
                </label>
                <label
                  data-text={boot.data.label9}
                  htmlFor="phone"
                  className="profile__data-form-label profile__data-form-label--phone"
                >
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="profile__data-form-input"
                    defaultValue={dataUser.phone}
                  />
                </label>
              </div>
              <div className="profile__data-form-buttons">
                {/* <button type="button" className="profile__data-form-button profile__data-form-button--cancel">
                    {boot.cancel}
                  </button> */}
                <button type="submit" className="profile__data-form-button">
                  {boot.save}
                </button>
              </div>
            </form>
          </div>
          <div className="profile__data-password">
            <h2 className="profile__data-title">
              {boot.data.form2Title}
              <span className="profile__data-title-text">
                {boot.data.legend}
              </span>
            </h2>
            <form onSubmit={handlePassword} className="profile__data-form">
              <div className="profile__data-form-grid profile__data-form-grid--password">
                <label
                  data-text={boot.data.label10}
                  htmlFor="currentPassword"
                  className="profile__data-form-label"
                >
                  <input
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    className="profile__data-form-input"
                    required
                  />
                </label>
                <label
                  data-text={boot.data.label11}
                  htmlFor="newPassword"
                  className="profile__data-form-label"
                >
                  <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    className="profile__data-form-input"
                    required
                  />
                </label>
                <label
                  data-text={boot.data.label12}
                  htmlFor="confirmPassword"
                  className="profile__data-form-label"
                >
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="profile__data-form-input"
                    required
                  />
                </label>
              </div>
              <div className="profile__data-form-buttons">
                {/* <button type="button" className="profile__data-form-button profile__data-form-button--cancel">
                    {boot.cancel}
                  </button> */}
                <button type="submit" className="profile__data-form-button">
                  {boot.save}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {tabActive.id === 1 ? (
        <div className="profile__documents">
          <h2 className="profile__title">{boot.documents.title}</h2>
          <p className="profile__description">{boot.documents.description}</p>
          <div className="profile__documents-form-container">
            <form
              onSubmit={handleDocuments}
              className="profile__documents-form"
            >
              <div className="profile__documents-form-grid">
                <div className="profile__documents-form-box">
                  <h3 className="profile__documents-form-title">
                    {boot.documents.titleForm1}
                  </h3>
                  <label
                    data-text={boot.documents.label1}
                    htmlFor="front"
                    className="profile__documents-form-label"
                  >
                    <input
                      type="text"
                      name="front"
                      id="front"
                      className="profile__documents-form-input"
                      readOnly
                    />
                    <input
                      type="file"
                      id="fileFront"
                      style={{ visibility: "hidden", position: "absolute" }}
                      name="front"
                      data-filetype="3"
                      data-extratype="Front"
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="profile__documents-form-upload"
                      onClick={() => {
                        handleDocument("fileFront");
                      }}
                    >
                      {boot.documents.button}
                    </button>
                  </label>
                </div>
                <div className="profile__documents-form-box">
                  <h3 className="profile__documents-form-title profile__documents-form-title--transparent">
                    {boot.documents.titleForm1}
                  </h3>
                  <label
                    data-text={boot.documents.label2}
                    htmlFor="lap"
                    className="profile__documents-form-label"
                  >
                    <input
                      type="text"
                      name="lap"
                      id="lap"
                      className="profile__documents-form-input"
                      readOnly
                    />
                    <input
                      type="file"
                      id="fileBack"
                      name="lap"
                      data-filetype="3"
                      data-extratype="Back"
                      style={{ visibility: "hidden", position: "absolute" }}
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="profile__documents-form-upload"
                      onClick={() => {
                        handleDocument("fileBack");
                      }}
                    >
                      {boot.documents.button}
                    </button>
                  </label>
                </div>
                <div className="profile__documents-form-box">
                  <h3 className="profile__documents-form-title">
                    {boot.documents.titleForm2}
                  </h3>
                  <label
                    data-text={boot.documents.label3}
                    htmlFor="residence"
                    className="profile__documents-form-label"
                  >
                    <input
                      type="text"
                      name="residence"
                      id="residence"
                      className="profile__documents-form-input"
                      readOnly
                    />
                    <input
                      type="file"
                      id="fileResidence"
                      style={{ visibility: "hidden", position: "absolute" }}
                      name="residence"
                      data-filetype="2"
                      data-extratype=""
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="profile__documents-form-upload"
                      onClick={() => {
                        handleDocument("fileResidence");
                      }}
                    >
                      {boot.documents.button}
                    </button>
                  </label>
                </div>
                <div className="profile__documents-form-box">
                  <h3 className="profile__documents-form-title">
                    {boot.documents.titleForm3}
                  </h3>
                  <label
                    data-text={boot.documents.label4}
                    htmlFor="verificationForm"
                    className="profile__documents-form-label"
                  >
                    <input
                      type="text"
                      name="verificationForm"
                      id="verificationForm"
                      className="profile__documents-form-input"
                      readOnly
                    />
                    <input
                      type="file"
                      id="fileVerification"
                      style={{ visibility: "hidden", position: "absolute" }}
                      name="verificationForm"
                      data-filetype="6"
                      data-extratype=""
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="profile__documents-form-upload"
                      onClick={() => {
                        handleDocument("fileVerification");
                      }}
                    >
                      {boot.documents.button}
                    </button>
                  </label>
                </div>
                <div className="profile__documents-form-box">
                  <h3 className="profile__documents-form-title">
                    {boot.documents.titleForm4}
                  </h3>
                  <label
                    data-text={boot.documents.label5}
                    htmlFor="card"
                    className="profile__documents-form-label"
                  >
                    <input
                      type="text"
                      name="card"
                      id="card"
                      className="profile__documents-form-input"
                      readOnly
                    />
                    <input
                      type="file"
                      id="fileCard"
                      style={{ visibility: "hidden", position: "absolute" }}
                      name="card"
                      data-filetype="4"
                      data-extratype=""
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="profile__documents-form-upload"
                      onClick={() => {
                        handleDocument("fileCard");
                      }}
                    >
                      {boot.documents.button}
                    </button>
                  </label>
                </div>
                <div className="profile__documents-form-box">
                  <h3 className="profile__documents-form-title">
                    {boot.documents.titleForm5}
                  </h3>
                  <label
                    data-text={boot.documents.label6}
                    htmlFor="voucher"
                    className="profile__documents-form-label"
                  >
                    <input
                      type="text"
                      name="voucher"
                      id="voucher"
                      className="profile__documents-form-input"
                      readOnly
                    />
                    <input
                      type="file"
                      id="fileVoucher"
                      style={{ visibility: "hidden", position: "absolute" }}
                      name="voucher"
                      data-filetype="7"
                      data-extratype=""
                      onChange={handleChangeFile}
                    />
                    <button
                      type="button"
                      className="profile__documents-form-upload"
                      onClick={() => {
                        handleDocument("fileVoucher");
                      }}
                    >
                      {boot.documents.button}
                    </button>
                  </label>
                </div>
              </div>
              <div className="profile__documents-form-legend-box">
                <span className="profile__documents-form-legend">
                  {boot.documents.legend}
                </span>
                <button
                  type="submit"
                  className="profile__documents-form-submit"
                >
                  {boot.documents.save}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </section>
  );
}
