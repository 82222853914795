import React, { useEffect, useState } from "react";
import getBaseApi from "@routes";
import { useAppContext } from "@context";

import "@stylesPages/Support.scss";

import Hero from "@components/Hero";
import Table from "@components/Table";

export default function Support({ boot }) {
  const [{ user, sessionId }, dispatch] = useAppContext();
  const [tickets, setTickets] = useState({ closed: [], open: [] });
  const columns = [
    {
      label: boot.tableColumn1,
      name: "idTransaction"
    },
    {
      label: boot.tableColumn2,
      name: "tpId"
    },
    {
      label: boot.tableColumn3,
      name: "amount"
    },
    {
      label: boot.tableColumn4,
      name: "currency"
    },
    {
      label: boot.tableColumn5,
      name: "status"
    }
  ];

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${getBaseApi()}/manage/Ticket?ID=${user.oyapHSrXRL}`, {
          headers: {
            Authorization: `Bearer ${sessionId}`
          }
        });
        const { data, error } = await response.json();
        if (error) {
          dispatch({ type: "LOGOUT" });
        }
        if (data) {
          setTickets({
            closed: data.closed.map((close) => ({ ...close, id: close.idTransaction })),
            open: data.open.map((open) => ({ ...open, id: open.idTransaction }))
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    })();
  }, []);

  return (
    <section className="support">
      <Hero
        title={boot.title}
      />
      <div className="support__content">
        <h2 className="support__title">{boot.title2}</h2>
        <h3 className="support__subtitle">{boot.table1Title}</h3>
        <Table columns={columns} data={tickets.open} />
        <br />
        <br />
        <br />
        <br />
        <h3 className="support__subtitle">{boot.table2Title}</h3>
        <Table columns={columns} data={tickets.closed} />
      </div>
    </section>
  );
}
