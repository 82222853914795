import React from "react";

import "@stylesComponents/Hero.scss";

export default function Hero({ title, tabs, activeTab, changeTab }) {
  return (
    <div className="hero">
      <h1 className="hero__title">{title}</h1>
      {
        tabs && (
          <div className="hero__tabs">
            {
              tabs.map(({ label, id }) => (
                <button
                  key={id}
                  onClick={() => changeTab({ label, id })}
                  type="button"
                  className={`hero__tab${id === activeTab.id ? " hero__tab--active" : ""}`}
                >
                  {label}
                </button>
              ))
            }
          </div>
        )
      }
    </div>
  );
}
