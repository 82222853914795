import React from "react";
import { useAppContext } from "@context";

import "@stylesComponents/Language.scss";

import LangIcon from "@icons/lang_icon.svg";

export default function Language() {
  const [{ lang }, dispatch] = useAppContext();
  const handleChangeLanguage = () => {
    dispatch({ type: "CHANGE_LANGUAGE" });
  };

  return (
    <div className="language">
      <img src={LangIcon} alt="" className="language__icon" />
      {lang}
      <div className="language__menu">
        <button onClick={handleChangeLanguage} className="language__menu-button" type="button" disabled={lang === "es"}>
          ES
        </button>
        <button onClick={handleChangeLanguage} className="language__menu-button" type="button" disabled={lang === "en"}>
          EN
        </button>
      </div>
    </div>
  );
}
